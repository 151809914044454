import { PosizioneAssicurazioniDto, PosizioneFinanziamentiDto, PosizioneGestionePatrimoniali, PosizioneInvestimentiDto, PosizioneLiquiditaDto, RiepilogoPatrimonioDisponibilitaDto, RiepilogoPatrimonioDto } from "@sparkasse/commons";
import { currencyFormat, defaultCurrency } from "helpers/utilities";
import React from "react";
import { useTranslation } from "react-i18next";
import PatrimonioAccordion from "./patrimonioComponent/patrimonio.accordion.component";
import AssicurazioniTable from "./patrimonioComponent/patrimonio.assicurazioni.table.component";
import FinanzamentiTable from "./patrimonioComponent/patrimonio.finanzamenti.table.component";
import GestionePatrimonialiTable from "./patrimonioComponent/patrimonio.gestionePatrimoniali.table.component";
import InvestimentiTable from "./patrimonioComponent/patrimonio.investimenti.table.component";
import LiquiditaTable from "./patrimonioComponent/patrimonio.liquidita.table.component";
import PatrimonioTableWraper from "./patrimonioComponent/patrimonio.tableWrap.component";

interface Props {
  data?: RiepilogoPatrimonioDto
  patrimonioTotaleDisponibilita: RiepilogoPatrimonioDisponibilitaDto | undefined
  patrimonioTotaleFinanziamenti: number | undefined
  patrimonioTotaleGestionePatrimoniale: number | undefined
}

const PatrimonioTableView: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  const { data, patrimonioTotaleDisponibilita, patrimonioTotaleFinanziamenti, patrimonioTotaleGestionePatrimoniale } = props;

  return (
    <div>

      <PatrimonioTableWraper
        sectionTitle={t("patrimonio.sezione.titolo.bankingLiquidita")}
        totalTitle={t(`patrimonio.totale.bankingLiquidita`)}
        totalValue={currencyFormat(data?.totaleLiquidita)}
        accordionHeaderRight={t("patrimonio.tableLabels.saldoContabile")}
        accordionHeaderLeft={t("patrimonio.tableLabels.tipoRapporto")}
      >
        <>
          {data?.posizioneLiquiditaList?.map(
            (posizioneLiquiditaItem: PosizioneLiquiditaDto, index: number) => {
              return (
                posizioneLiquiditaItem.liquiditaContiListVisibile && (
                  <PatrimonioAccordion key={`item-${index}`}
                    item={posizioneLiquiditaItem}
                    expandable
                    expand
                    fielName="totaleSaldoContabile"
                  >
                    <LiquiditaTable
                      data={posizioneLiquiditaItem.liquiditaContiList}
                      type={posizioneLiquiditaItem.tipologia}
                    />
                  </PatrimonioAccordion>
                )
              );
            }
          )}
        </>
      </PatrimonioTableWraper>

      <PatrimonioTableWraper
        sectionTitle={t("patrimonio.sezione.titolo.investimenti")}
        totalTitle={t(`patrimonio.totale.investimenti`)}
        totalValue={currencyFormat(data?.totaleInvestimenti)}
        accordionHeaderRight={t("patrimonio.tableLabels.controvalore")}
        accordionHeaderLeft={t("patrimonio.tableLabels.tipoTitolo")}
      >
        <>
          {data?.posizioneInvestimentiList?.map(
            (posizioneInvestimentiItem: PosizioneInvestimentiDto, index: number) => {
              return (
                posizioneInvestimentiItem.investimentiContiListVisibile && (
                  <PatrimonioAccordion
                    key={`item-${index}`}
                    item={posizioneInvestimentiItem}
                    expandable
                    expand
                    fielName="totaleControvalore"
                  >
                    <InvestimentiTable
                      data={posizioneInvestimentiItem.investimentiContiList}
                    />
                  </PatrimonioAccordion>
                )
              );
            }
          )}
        </>
      </PatrimonioTableWraper>

      {data?.posizioneGestionePatrimonialiVisibile && (
        <PatrimonioTableWraper
          sectionTitle={t("patrimonio.sezione.titolo.gestionePatrimoniale")}
          totalTitle={t(`patrimonio.totale.gestionePatrimoniale`)}
          totalValue={currencyFormat(data?.totaleGestionePatrimoniale)}
          accordionHeaderRight={''}
          accordionHeaderLeft={''}
        >
          <>
            {data?.posizioneGestionePatrimonialiList?.map(


              (posizioneGestionePatrimonialiItem: PosizioneGestionePatrimoniali, index: number) => {
                return (
                  posizioneGestionePatrimonialiItem.gestionePatrimonialeListVisibile && (

                    <PatrimonioAccordion
                      key={`item-${index}`}
                      item={posizioneGestionePatrimonialiItem}
                      expandable
                      expand
                      fielName={"totaleSaldoContabile"}
                    >
                      {posizioneGestionePatrimonialiItem && (
                        <GestionePatrimonialiTable
                          data={posizioneGestionePatrimonialiItem}
                        />
                      )}
                    </PatrimonioAccordion>
                  )

                );
              }
            )}
          </>
        </PatrimonioTableWraper>
      )}

      {data?.posizioneAssicurazioniList &&
        data?.posizioneAssicurazioniList[0].assicurazioniContoListVisibile && (
          <PatrimonioTableWraper
            sectionTitle={t("patrimonio.sezione.titolo.assicurazioni")}
            totalTitle={t(`patrimonio.totale.assicurazioni`)}
            totalValue={currencyFormat(data?.totaleAssicurazioni)}
            accordionHeaderRight={t("patrimonio.tableLabels.ibanIntestatario")}
            accordionHeaderLeft={t("patrimonio.tableLabels.controvalore")}
            isAssicuracione={true}
          >
            <>
              {data?.posizioneAssicurazioniList?.map(
                (posizioneAssicurazioniItem: PosizioneAssicurazioniDto, index: number) => {
                  return (
                    posizioneAssicurazioniItem.assicurazioniContoListVisibile && (

                      <>
                        {posizioneAssicurazioniItem.assicurazioniContoList?.map((item) => {
                          return (<AssicurazioniTable
                            data={
                              item
                            }
                          />)
                        })}
                      </>

                    )
                  );
                }
              )}
            </>
          </PatrimonioTableWraper>
        )
      }

      <PatrimonioTableWraper
        sectionTitle={t("patrimonio.sezione.titolo.finanziamenti")}
        totalTitle={t(`patrimonio.totale.finanziamenti`)}
        totalValue={currencyFormat(data?.totaleFinanziamenti)}
        accordionHeaderRight={t("patrimonio.tableLabels.controvalore")}
        accordionHeaderLeft={t("patrimonio.tableLabels.tipoFinanziamento")}
        isNegative={true}
      >
        <>
          {data?.posizioneFinanziamentiList?.map(
            (posizioneFinanziamentiItem: PosizioneFinanziamentiDto, index: number) => {
              return (
                posizioneFinanziamentiItem.finanziamentiContoListVisibile && (
                  <PatrimonioAccordion
                    key={`item-${index}`}
                    item={posizioneFinanziamentiItem}
                    expandable
                    expand
                    fielName={posizioneFinanziamentiItem.tipologia === "1" ? "saldoContabile" : "totaleSaldoCapitaleResiduo"}
                  >
                    <FinanzamentiTable
                      data={posizioneFinanziamentiItem.finanziamentiContoList}
                      tipologia={posizioneFinanziamentiItem.tipologia}
                    />
                  </PatrimonioAccordion>
                )
              );
            }
          )}
        </>
      </PatrimonioTableWraper>

      <div className="patrimoniText">
        <p>
          {t("patrimonio.paragraph")}
        </p>
      </div>

      <div className="patrimonio-totaleInfo">
        <p className="patrimonio-totaleInfo_netto">
          <span className="patrimonio-totaleInfo_netto_label">
            {`${t("patrimonio.lordo.totaleNetto")}: `}
          </span>
          <span
            className="patrimonio-totaleInfo_netto_value"
            style={{ color: Math.sign(patrimonioTotaleDisponibilita?.disponibilitaNetto || 0) === 1 ? '#006731' : '#b2001c' }}
          >
            {currencyFormat(patrimonioTotaleDisponibilita?.disponibilitaNetto)}
            <span className="patrimonio-totaleInfo_netto_currency">{` ${defaultCurrency}`}</span>
          </span>
        </p>
        <div className="patrimonio-totaleInfo_values">

          <p className="patrimonio-totaleInfo_values_lordo">
            <span className="patrimonio-totaleInfo_values_lordo_label">
              {`${t("patrimonio.lordo.title")}: `}
            </span>
            <span className="patrimonio-totaleInfo_values_lordo_value">
              {currencyFormat(patrimonioTotaleDisponibilita?.disponibilitaLordo)}
              <span className="patrimonio-totaleInfo_netto_currency">{` ${defaultCurrency}`}</span>
            </span>
          </p>

          <p className="patrimonio-totaleInfo_values_lordo">
            <span className="patrimonio-totaleInfo_values_lordo_label">
              {`${t("patrimonio.totale.finanziamenti")}: `}
            </span>
            <span className="patrimonio-totaleInfo_values_lordo_value">
              {currencyFormat(Math.abs(patrimonioTotaleFinanziamenti || 0))}
              <span className="patrimonio-totaleInfo_netto_currency">{` ${defaultCurrency}`}</span>
            </span>
          </p>

        </div>
      </div>

    </div>
  );
};
export default PatrimonioTableView;

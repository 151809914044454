import { adeguataVerificaReducer, altoAdigePassReducer, anatocismoReducer, assegniReducer, bancomatPayReducer, bannerReducer, bilancioReducer, bloccoCarteReducer, bollettinoFrecciaReducer, bollettinoPostaleReducer, bolloAciReducer, bonificoDepositoReducer, bonificoEsteroReducer, bonificoRipetitivoReducer, bonificoSepaReducer, bozzeReducer, cambiReducer, cartaContoReducer, catalogoProdottiReducer, cBillReducer, cercaTitoliReducer, collaborationReducer, compravenditaReducer, consensiReducer, datiFilialeReducer, datiPersonaliReducer, depositoRisparmiReducer, documentiReducer, dossierTitoliReducer, elencoRapportiReducer, elencoUltimiAccessiReducer, f24Reducer, faqReducer, filialeVirtualeReducer, finanzaReducer, firmaDigitaleReducer, fondiPaperlessReducer, generalReducer, i18nReducer, indiciReducer, limitiOperativiReducer, loginReducer, mandatiSDDReducer, mavReducer, menuReducer, messaggiReducer, MIFIDReducer, movimentiReducer, mutuiReducer, myBankReducer, neosuranceReducer, newsReducer, nexiDebitReducer, nexiReducer, notificePushReducer, onboardingReducer, operazioniOneClickReducer, operazioniReducer, pagamentiReducer, patrimonioReducer, pctReducer, pfmReducer, pnsReducer, polizzeReducer, postloginReducer, preloginReducer, prestitiReducer, prestitoIdeaReducer, profiloReducer, psd2Reducer, ravReducer, rendimentiReducer, ribaReducer, ricaricaCartaReducer, ricaricaCellulareReducer, ricercaFilialeAtmReducer, richiediFidoIsiDispoReducer, RootState, rubricaContattiReducer, storicoOrdiniReducer, timeDepositReducer, titoliQuotatiReducer, tributiLocaliReducer, variazioneCanaliReducer, watchlistReducer, widgetsReducer, zainettoFiscaleReducer } from "@sparkasse/commons";
import { combineReducers } from "redux";

const state: RootState = {
  altoAdigePass: altoAdigePassReducer,
  anatocismo: anatocismoReducer,
  assegni: assegniReducer,
  bancomatPay: bancomatPayReducer,
  banner: bannerReducer,
  bilancio: bilancioReducer,
  bloccoCarte: bloccoCarteReducer,
  bollettinoFreccia: bollettinoFrecciaReducer,
  bollettinoPostale: bollettinoPostaleReducer,
  bolloAci: bolloAciReducer,
  bonificoDeposito: bonificoDepositoReducer,
  bonificoEstero: bonificoEsteroReducer,
  bonificoRipetitivo: bonificoRipetitivoReducer,
  bonificoSepa: bonificoSepaReducer,
  bozze: bozzeReducer,
  cBill: cBillReducer,
  cambi: cambiReducer,
  cartaConto: cartaContoReducer,
  catalogoProdotti: catalogoProdottiReducer,
  collaboration: collaborationReducer,
  compravendita: compravenditaReducer,
  consensi: consensiReducer,
  datiFiliale: datiFilialeReducer,
  datiPersonali: datiPersonaliReducer,
  depositoRisparmi: depositoRisparmiReducer,
  documenti: documentiReducer,
  dossierTitoli: dossierTitoliReducer,
  elencoRapporti: elencoRapportiReducer,
  elencoUltimiAccessi: elencoUltimiAccessiReducer,
  f24: f24Reducer,
  faq: faqReducer,
  filialeVirtuale: filialeVirtualeReducer,
  finanza: finanzaReducer,
  firmaDigitale: firmaDigitaleReducer,
  general: generalReducer,
  i18n: i18nReducer,
  indici: indiciReducer,
  limitiOperativi: limitiOperativiReducer,
  login: loginReducer,
  mandatiSDD: mandatiSDDReducer,
  mav: mavReducer,
  menu: menuReducer,
  messaggi: messaggiReducer,
  movimenti: movimentiReducer,
  mutui: mutuiReducer,
  myBank: myBankReducer,
  neosurance: neosuranceReducer,
  news: newsReducer,
  nexi: nexiReducer,
  notificePush: notificePushReducer,
  onboarding: onboardingReducer,
  oneclick: operazioniOneClickReducer,
  operazioni: operazioniReducer,
  pagamenti: pagamentiReducer,
  patrimonio: patrimonioReducer,
  pct: pctReducer,
  pfm: pfmReducer,
  pns: pnsReducer,
  polizze: polizzeReducer,
  postlogin: postloginReducer,
  prelogin: preloginReducer,
  prestiti: prestitiReducer,
  prestitoIdea: prestitoIdeaReducer,
  profilo: profiloReducer,
  psd2: psd2Reducer,
  rav: ravReducer,
  rendimenti: rendimentiReducer,
  riba: ribaReducer,
  ricaricaCellulare: ricaricaCellulareReducer,
  ricercaFilialeAtm: ricercaFilialeAtmReducer,
  richiediFidoIsiDispo: richiediFidoIsiDispoReducer,
  rubricaContatti: rubricaContattiReducer,
  storicoOrdini: storicoOrdiniReducer,
  timeDeposit: timeDepositReducer,
  titoli: cercaTitoliReducer,
  titoliQuotati: titoliQuotatiReducer,
  tributiLocali: tributiLocaliReducer,
  variazioneCanali: variazioneCanaliReducer,
  watchlist: watchlistReducer,
  widgets: widgetsReducer,
  mifid: MIFIDReducer,
  fondiPaperless: fondiPaperlessReducer,
  adeguataVerifica: adeguataVerificaReducer,
  zainettoFiscale: zainettoFiscaleReducer,
  ricaricaCarta: ricaricaCartaReducer,
  nexiDebit: nexiDebitReducer,
};

export const reducerStore = combineReducers(state);

export type AppState = ReturnType<typeof reducerStore>;

"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PublicGenericControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class PublicGenericControllerApi extends runtime.BaseAPI {
  /**
   * Check stato notifica per POC Genesys
   */
  checkNotifichePOCRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.ndgtoken === null || requestParameters.ndgtoken === undefined) {
        throw new runtime.RequiredError('ndgtoken', 'Required parameter requestParameters.ndgtoken was null or undefined when calling checkNotifichePOC.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/public/genesys/{ndgtoken}/polling".replace("{".concat("ndgtoken", "}"), encodeURIComponent(String(requestParameters.ndgtoken))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.OnBoardSessionEntityFromJSON(jsonValue));
    });
  }
  /**
   * Check stato notifica per POC Genesys
   */
  checkNotifichePOC(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.checkNotifichePOCRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Salva un log applicativo per un azione di front end
   */
  extLogginOperationRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.operationLogDto === null || requestParameters.operationLogDto === undefined) {
        throw new runtime.RequiredError('operationLogDto', 'Required parameter requestParameters.operationLogDto was null or undefined when calling extLogginOperation.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/public/extlogging",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.OperationLogDtoToJSON(requestParameters.operationLogDto)
      });
      return new runtime.TextApiResponse(response);
    });
  }
  /**
   * Salva un log applicativo per un azione di front end
   */
  extLogginOperation(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.extLogginOperationRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Richiama il serivizio di info utente Cedacri
   */
  getInfoUtenteRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.infotUtentereqInDto === null || requestParameters.infotUtentereqInDto === undefined) {
        throw new runtime.RequiredError('infotUtentereqInDto', 'Required parameter requestParameters.infotUtentereqInDto was null or undefined when calling getInfoUtente.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/public/genesys/infoutente",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.InfotUtentereqInDtoToJSON(requestParameters.infotUtentereqInDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.InfoUtenteOutDtoFromJSON(jsonValue));
    });
  }
  /**
   * Richiama il serivizio di info utente Cedacri
   */
  getInfoUtente(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getInfoUtenteRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Servizio di redirect
   */
  redirect1Raw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.signres === null || requestParameters.signres === undefined) {
        throw new runtime.RequiredError('signres', 'Required parameter requestParameters.signres was null or undefined when calling redirect1.');
      }
      if (requestParameters.sID === null || requestParameters.sID === undefined) {
        throw new runtime.RequiredError('sID', 'Required parameter requestParameters.sID was null or undefined when calling redirect1.');
      }
      const queryParameters = {};
      if (requestParameters.signres !== undefined) {
        queryParameters['signres'] = requestParameters.signres;
      }
      if (requestParameters.sID !== undefined) {
        queryParameters['SID'] = requestParameters.sID;
      }
      if (requestParameters.test !== undefined) {
        queryParameters['test'] = requestParameters.test;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: "/public/firma/esito/redirect",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.TextApiResponse(response);
    });
  }
  /**
   * Servizio di redirect
   */
  redirect1(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.redirect1Raw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Invia notifica per richiedere autenticazione
   */
  sendNotificheRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.sendNotificationInDto === null || requestParameters.sendNotificationInDto === undefined) {
        throw new runtime.RequiredError('sendNotificationInDto', 'Required parameter requestParameters.sendNotificationInDto was null or undefined when calling sendNotifiche.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/public/genesys/send",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.SendNotificationInDtoToJSON(requestParameters.sendNotificationInDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.SendNotificationOutDtoFromJSON(jsonValue));
    });
  }
  /**
   * Invia notifica per richiedere autenticazione
   */
  sendNotifiche(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.sendNotificheRaw(requestParameters);
      return yield response.value();
    });
  }
}
exports.PublicGenericControllerApi = PublicGenericControllerApi;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetNexi = exports.resetFiltriNexi = exports.esportaMovimentiNexiExcel = exports.esportaDettaglioMovimentoNexiPDF = exports.callNexiServlet = exports.callNexiServletReset = exports.resetTokenSsoNexi = exports.getMovimentiNexi = exports.getParametriNexi = exports.verificaConsensoNexi = exports.variaConsensoNexi = exports.getTokenSSONexi = exports.setRapportoSelezionatoNexi = exports.setNexiSetOrdinamento = exports.setNexiSetOffset = exports.setNexiSetEsercente = exports.setNexiSetImporto = exports.setNexiSetPeriodo = exports.setNexiSetDataDa = exports.setNexiSetDataA = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const moment = require("moment");
function setNexiSetDataA(txt) {
  return {
    type: __1.EReduxActionTypes.NEXI_DATA_A,
    payload: txt
  };
}
exports.setNexiSetDataA = setNexiSetDataA;
function setNexiSetDataDa(txt) {
  return {
    type: __1.EReduxActionTypes.NEXI_DATA_DA,
    payload: txt
  };
}
exports.setNexiSetDataDa = setNexiSetDataDa;
function setNexiSetPeriodo(txt) {
  return {
    type: __1.EReduxActionTypes.NEXI_PERIODO,
    payload: txt
  };
}
exports.setNexiSetPeriodo = setNexiSetPeriodo;
function setNexiSetImporto(num) {
  return {
    type: __1.EReduxActionTypes.NEXI_IMPORTO,
    payload: num
  };
}
exports.setNexiSetImporto = setNexiSetImporto;
function setNexiSetEsercente(txt) {
  return {
    type: __1.EReduxActionTypes.NEXI_ESERCENTE,
    payload: txt
  };
}
exports.setNexiSetEsercente = setNexiSetEsercente;
function setNexiSetOffset(offset) {
  return {
    type: __1.EReduxActionTypes.NEXI_OFFSET,
    payload: offset
  };
}
exports.setNexiSetOffset = setNexiSetOffset;
function setNexiSetOrdinamento(val) {
  return {
    type: __1.EReduxActionTypes.NEXI_ORDINAMENTO,
    payload: val
  };
}
exports.setNexiSetOrdinamento = setNexiSetOrdinamento;
/* get token SSO - START */
function getTokenSSONexiStart() {
  return {
    type: __1.EReduxActionTypes.NEXI_GET_TOKEN_SSO_START
  };
}
;
function getTokenSSONexiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.NEXI_GET_TOKEN_SSO_SUCCESS,
    payload
  };
}
;
function generalError(value) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value));
  };
}
;
const setRapportoSelezionatoNexi = rapporto => {
  return {
    type: __1.EReduxActionTypes.NEXI_SET_RAPPORTO_SELEZIONATO,
    payload: rapporto
  };
};
exports.setRapportoSelezionatoNexi = setRapportoSelezionatoNexi;
const getTokenSSONexi = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getTokenSSONexiStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/parametri_movimenti.json')).then(parametri => {
      dispatch(getTokenSSONexiSuccess((0, api_rest_1.CartaSiSSoDtoFromJSON)({})));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.NexiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).tokenSSONexi().then(response => {
        dispatch(getTokenSSONexiSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getTokenSSONexi = getTokenSSONexi;
/* get token SSO - FINE */
/* varia consenso - START */
function variaConsensoNexiStart() {
  return {
    type: __1.EReduxActionTypes.NEXI_VARIA_CONSENSO_START
  };
}
;
function variaConsensoNexiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.NEXI_VARIA_CONSENSO_SUCCESS,
    payload
  };
}
;
const variaConsensoNexi = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(variaConsensoNexiStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/parametri_consenso.json')).then(parametri => {
      dispatch(variaConsensoNexiSuccess((0, api_rest_1.ConsensoNexiDtoFromJSON)(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const requestParameters = {
      codiceConsenso: "C6",
      variazioneNexiRequestDto: {
        value: "S"
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.NexiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).variaConsensoNexi(requestParameters).then(response => {
        dispatch(verificaConsensoNexiSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.variaConsensoNexi = variaConsensoNexi;
/* varia consenso - FINE */
/* verifica consenso - START */
function verificaConsensoNexiStart() {
  return {
    type: __1.EReduxActionTypes.NEXI_VERIFICA_CONSENSO_START
  };
}
;
function verificaConsensoNexiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.NEXI_VERIFICA_CONSENSO_SUCCESS,
    payload
  };
}
;
const verificaConsensoNexi = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(verificaConsensoNexiStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/parametri_consenso.json')).then(parametri => {
      dispatch(verificaConsensoNexiSuccess((0, api_rest_1.ConsensoNexiDtoFromJSON)(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const requestParameters = {
      codiceConsenso: "C6"
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.NexiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).verificaConsensoNexi(requestParameters).then(response => {
        dispatch(verificaConsensoNexiSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.verificaConsensoNexi = verificaConsensoNexi;
/* verifica consenso - FINE */
/* verifica consenso - START */
function getParametriNexiStart() {
  return {
    type: __1.EReduxActionTypes.NEXI_GET_PARAMETRI_START
  };
}
;
function getParametriNexiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.NEXI_GET_PARAMETRI_SUCCESS,
    payload
  };
}
;
const getParametriNexi = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getParametriNexiStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/parametri_nexi.json')).then(response => {
      dispatch(getParametriNexiSuccess((0, api_rest_1.ParametriNexiDtoFromJSON)(response)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.NexiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriNexi().then(response => {
        var _a;
        dispatch(getParametriNexiSuccess(response));
        !!(response === null || response === void 0 ? void 0 : response.rapporti) && dispatch((0, exports.setRapportoSelezionatoNexi)((_a = response === null || response === void 0 ? void 0 : response.rapporti) === null || _a === void 0 ? void 0 : _a[0]));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getParametriNexi = getParametriNexi;
function getMovimentiNexiStart() {
  return {
    type: __1.EReduxActionTypes.NEXI_GET_MOVIMENTI_START
  };
}
;
function getMovimentiNexiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.NEXI_GET_MOVIMENTI_SUCCESS,
    payload
  };
}
;
const IMPORTO_MOLTIPLICATORI_INTERVALLO = {
  MIN: 0.8,
  MAX: 1.2
};
const getMovimentiNexi = () => async (dispatch, getState, extraArguments) => {
  var _a, _b, _c, _d, _e, _f;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getMovimentiNexiStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/nexiListaMovimentiCarta.json')).then(response => {
      dispatch(getMovimentiNexiSuccess((0, api_rest_1.ListaMovimentiNexiDtoFromJSON)(response)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const nexiForm = getState().nexi.form;
    const requestParameters = {
      movimentiCartaRequestDto: {
        intestatario: (_b = (_a = nexiForm.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.carta) === null || _b === void 0 ? void 0 : _b.intestazione,
        panAlias: (_f = (_e = (_d = (_c = nexiForm.rapportoSelezionato) === null || _c === void 0 ? void 0 : _c.carta) === null || _d === void 0 ? void 0 : _d.id) === null || _e === void 0 ? void 0 : _e.value) !== null && _f !== void 0 ? _f : '-',
        dataIniziale: moment(nexiForm.dataDa).toDate(),
        dataFinale: moment(nexiForm.dataA).toDate(),
        importoDa: (0, helpers_1.formattedNumberToNumber)(nexiForm.importo) * IMPORTO_MOLTIPLICATORI_INTERVALLO.MIN || undefined,
        importoA: (0, helpers_1.formattedNumberToNumber)(nexiForm.importo) * IMPORTO_MOLTIPLICATORI_INTERVALLO.MAX || undefined,
        ordinamento: api_rest_1.MovimentiCartaRequestDtoOrdinamentoEnum.D,
        divisa: undefined,
        offset: nexiForm.offset,
        keyword: nexiForm.esercente,
        idPeriodo: nexiForm.periodo
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.NexiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).recuperaMovimentiCarta(requestParameters).then(response => {
        dispatch(getMovimentiNexiSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getMovimentiNexi = getMovimentiNexi;
/* verifica consenso - FINE */
function resetTokenSsoNexi() {
  return {
    type: __1.EReduxActionTypes.NEXI_RESET_TOKEN_SSO
  };
}
exports.resetTokenSsoNexi = resetTokenSsoNexi;
;
/* callNexiServlet */
function callNexiServletStart() {
  return {
    type: __1.EReduxActionTypes.NEXI_CALL_SERVLET_START
  };
}
;
function callNexiServletReset() {
  return {
    type: __1.EReduxActionTypes.NEXI_CALL_SERVLET_RESET
  };
}
exports.callNexiServletReset = callNexiServletReset;
;
function callNexiServletSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.NEXI_CALL_SERVLET_SUCCESS,
    payload
  };
}
;
const callNexiServlet = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(callNexiServletStart());
  if (extraArguments.mock) {
    dispatch((0, general_actions_1.setLoader)(false));
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.NexiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).callNexiServlet().then(response => {
        dispatch(callNexiServletSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.callNexiServlet = callNexiServlet;
/* esporta PDF dettaglio movimento - START */
function esportaDettaglioMovimentoNexiPDFStart() {
  return {
    type: __1.EReduxActionTypes.NEXI_ESPORTA_DETTAGLIO_PDF_START
  };
}
function esportaDettaglioMovimentoNexiPDFSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.NEXI_ESPORTA_DETTAGLIO_PDF_SUCCESS,
    payload
  };
}
const esportaDettaglioMovimentoNexiPDF = movimento => async (dispatch, getState, extraArguments) => {
  var _a;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch((0, general_actions_1.setWaitingSection)(true, "PDF".concat(movimento.movimentoId)));
  dispatch(esportaDettaglioMovimentoNexiPDFStart());
  if (extraArguments.mock) {
    dispatch((0, general_actions_1.setLoader)(false));
  } else {
    const dettaglioMovimentoNexiPdfRequest = {
      exportMovimentoCartaRequestDto: {
        cartaDto: (_a = getState().nexi.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.carta,
        movimentoDto: movimento
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.NexiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).dettaglioMovimentoNexiPdf(dettaglioMovimentoNexiPdfRequest).then(async response => {
        if (extraArguments.platform === 'desktop') {
          (0, helpers_1.hadlePdfResponse)(response, url => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
        }
        if (extraArguments.platform === 'mobile') {
          (0, helpers_1.download_sharePDF)(extraArguments, "movimento-".concat(movimento.movimentoId, ".pdf"), response);
        }
        dispatch(esportaDettaglioMovimentoNexiPDFSuccess(response));
      }).catch(async error => {
        try {
          dispatch(generalError(await (0, helpers_1.formatError)(error)));
        } catch (e) {
          console.log(e);
        }
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
        dispatch((0, general_actions_1.setWaitingSection)(false, "PDF".concat(movimento.movimentoId)));
      });
    });
  }
};
exports.esportaDettaglioMovimentoNexiPDF = esportaDettaglioMovimentoNexiPDF;
function esportaNexiExcelStart() {
  return {
    type: __1.EReduxActionTypes.NEXI_ESPORTA_EXCEL_START
  };
}
function esportaNexiExcelSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.NEXI_ESPORTA_EXCEL_SUCCESS,
    payload
  };
}
const esportaMovimentiNexiExcel = () => async (dispatch, getState, extraArguments) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  dispatch(esportaNexiExcelStart());
  dispatch((0, general_actions_1.setLoader)(true));
  if (extraArguments.mock) {
    dispatch((0, general_actions_1.setLoader)(false));
  } else {
    const stateNexi = getState().nexi;
    const nexiForm = stateNexi.form;
    const elencoMovimentiNexiExcelRequest = {
      movimentiCartaRequestDto: {
        intestatario: (_b = (_a = nexiForm.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.carta) === null || _b === void 0 ? void 0 : _b.intestazione,
        panAlias: (_f = (_e = (_d = (_c = nexiForm.rapportoSelezionato) === null || _c === void 0 ? void 0 : _c.carta) === null || _d === void 0 ? void 0 : _d.id) === null || _e === void 0 ? void 0 : _e.value) !== null && _f !== void 0 ? _f : '-',
        pan: (_h = (_g = nexiForm.rapportoSelezionato) === null || _g === void 0 ? void 0 : _g.carta) === null || _h === void 0 ? void 0 : _h.pan,
        idPeriodo: nexiForm.periodo,
        dataIniziale: moment(nexiForm.dataDa).toDate(),
        dataFinale: moment(nexiForm.dataA).toDate(),
        divisa: "EUR",
        importoDa: (0, helpers_1.formattedNumberToNumber)(nexiForm.importo) * IMPORTO_MOLTIPLICATORI_INTERVALLO.MIN || undefined,
        importoA: (0, helpers_1.formattedNumberToNumber)(nexiForm.importo) * IMPORTO_MOLTIPLICATORI_INTERVALLO.MAX || undefined,
        ordinamento: api_rest_1.MovimentiCartaRequestDtoOrdinamentoEnum.D,
        offset: undefined,
        keyword: undefined
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.NexiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/vnd.ms-excel')).elencoMovimentiNexiExcel(elencoMovimentiNexiExcelRequest).then(response => {
        if (extraArguments.platform === 'desktop') {
          (0, helpers_1.hadleExcelResponse)(response, 'movimentiCarta.xlsx');
        }
        if (extraArguments.platform === 'mobile') {
          (0, helpers_1.download_shareXLS)(extraArguments, 'movimentiCarta.xls', response);
        }
        dispatch(esportaNexiExcelSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.esportaMovimentiNexiExcel = esportaMovimentiNexiExcel;
function resetFiltriNexi() {
  return {
    type: __1.EReduxActionTypes.NEXI_RESET_FILTRO
  };
}
exports.resetFiltriNexi = resetFiltriNexi;
function resetNexi() {
  return {
    type: __1.EReduxActionTypes.NEXI_RESET
  };
}
exports.resetNexi = resetNexi;
"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WebhookNexiDebitControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class WebhookNexiDebitControllerApi extends runtime.BaseAPI {
  /**
   * Webhook aggiornamento stato acquisto nexi debit
   */
  webhookEsitoAcquistoNexiDebitRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling webhookEsitoAcquistoNexiDebit.');
      }
      if (requestParameters.webhookUpdateAcquistoNexiDebitInDto === null || requestParameters.webhookUpdateAcquistoNexiDebitInDto === undefined) {
        throw new runtime.RequiredError('webhookUpdateAcquistoNexiDebitInDto', 'Required parameter requestParameters.webhookUpdateAcquistoNexiDebitInDto was null or undefined when calling webhookEsitoAcquistoNexiDebit.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/public/nexidebit/acquisto/webhook/esito/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.WebhookUpdateAcquistoNexiDebitInDtoToJSON(requestParameters.webhookUpdateAcquistoNexiDebitInDto)
      });
      return new runtime.VoidApiResponse(response);
    });
  }
  /**
   * Webhook aggiornamento stato acquisto nexi debit
   */
  webhookEsitoAcquistoNexiDebit(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.webhookEsitoAcquistoNexiDebitRaw(requestParameters);
    });
  }
}
exports.WebhookNexiDebitControllerApi = WebhookNexiDebitControllerApi;
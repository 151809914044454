"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.nexiReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const moment = require("moment");
const initialState = {
  verificaConsenso: (0, api_rest_1.ConsensoNexiDtoFromJSON)({}),
  parametri: undefined,
  movimenti: undefined,
  form: {
    rapportoSelezionato: undefined,
    periodo: '',
    dataDa: '',
    dataDaError: '',
    dataA: '',
    dataAError: '',
    importo: undefined,
    importoError: '',
    esercente: '',
    offset: 0
  },
  nxsrv: undefined,
  tokenSSO: (0, api_rest_1.CartaSiSSoDtoFromJSON)({})
};
const nexiReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  var _a, _b;
  switch (action.type) {
    case __1.EReduxActionTypes.NEXI_GET_TOKEN_SSO_SUCCESS:
      return {
        ...state,
        tokenSSO: action.payload
      };
    case __1.EReduxActionTypes.NEXI_VERIFICA_CONSENSO_SUCCESS:
      return {
        ...state,
        verificaConsenso: action.payload
      };
    case __1.EReduxActionTypes.NEXI_GET_PARAMETRI_SUCCESS:
      return {
        ...state,
        parametri: action.payload
      };
    case __1.EReduxActionTypes.NEXI_RESET_TOKEN_SSO:
      return {
        ...state,
        tokenSSO: initialState.tokenSSO
      };
    case __1.EReduxActionTypes.NEXI_CALL_SERVLET_SUCCESS:
      return {
        ...state,
        nxsrv: action.payload
      };
    case __1.EReduxActionTypes.NEXI_CALL_SERVLET_RESET:
      return {
        ...state,
        nxsrv: initialState.nxsrv
      };
    case __1.EReduxActionTypes.NEXI_SET_RAPPORTO_SELEZIONATO:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoSelezionato: action.payload
        }
      };
    case __1.EReduxActionTypes.NEXI_IMPORTO:
      return {
        ...state,
        form: {
          ...state.form,
          importo: action.payload,
          importoError: ''
        }
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    case __1.EReduxActionTypes.NEXI_DATA_DA:
      return {
        ...state,
        form: {
          ...state.form,
          dataDa: action.payload,
          dataDaError: (0, helpers_1.validationFieldDate)(action.payload, '', false, '', state.form.dataA, true),
          dataAError: (0, helpers_1.validationFieldDate)(state.form.dataA, '', false, action.payload),
          periodo: initialState.form.periodo
        }
      };
    case __1.EReduxActionTypes.NEXI_DATA_A:
      return {
        ...state,
        form: {
          ...state.form,
          dataA: action.payload,
          dataAError: (0, helpers_1.validationFieldDate)(action.payload, '', false, state.form.dataDa),
          dataDaError: (0, helpers_1.validationFieldDate)(state.form.dataDa, '', false, '', action.payload, true),
          periodo: initialState.form.periodo
        }
      };
    case __1.EReduxActionTypes.NEXI_PERIODO:
      return {
        ...state,
        form: {
          ...state.form,
          periodo: action.payload
        }
      };
    case __1.EReduxActionTypes.NEXI_RESET_FILTRO:
      let periodo;
      if ((_a = state.parametri) === null || _a === void 0 ? void 0 : _a.parametri) {
        periodo = state.parametri.parametri['MOVIMENTI.PERIODI.RICERCA'].find(p => !!p.defaultValue);
      }
      return {
        ...state,
        form: {
          ...initialState.form,
          rapportoSelezionato: state.form.rapportoSelezionato,
          periodo: periodo === null || periodo === void 0 ? void 0 : periodo.idPeriodo,
          dataDa: moment().subtract((periodo === null || periodo === void 0 ? void 0 : periodo.giorni) || 0, 'days').format('YYYY-MM-DD'),
          dataA: moment().format('YYYY-MM-DD')
        }
      };
    case __1.EReduxActionTypes.NEXI_ESERCENTE:
      return {
        ...state,
        form: {
          ...state.form,
          esercente: action.payload
        }
      };
    case __1.EReduxActionTypes.NEXI_OFFSET:
      return {
        ...state,
        form: {
          ...state.form,
          offset: action.payload
        }
      };
    case __1.EReduxActionTypes.NEXI_GET_MOVIMENTI_SUCCESS:
      return {
        ...state,
        movimenti: !state.form.offset ? action.payload : {
          ...state.movimenti,
          movimenti: (((_b = state.movimenti) === null || _b === void 0 ? void 0 : _b.movimenti) || []).concat(action.payload.movimenti || [])
        }
      };
    case __1.EReduxActionTypes.NEXI_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.nexiReducer = nexiReducer;
exports.default = exports.nexiReducer;
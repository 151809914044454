import { DatiPagamentoDto, F24LookupDto, IGeneralError, Items, MessaggioAllegatoDto, RapportoCompletoDto, RapportoDto, RataFinanziamentoDtoStatoEnum, ResourcesDto, selectedAbi } from "@sparkasse/commons";
import { piwik } from "App";
import { Option } from "component/inputAutoCompleteGeneric/inputAutoComplete.component";
import { Item } from "component/inputSelection/inputSelection.component";
import i18next from 'i18next';
import moment, { Moment } from "moment";

export const placeholderForNullOrUndefined = '-';

export const dateFormat = (value?: any) => {
  if (value instanceof Date) {
    return moment(value.toISOString()).format("DD/MM/YYYY");
  } else if (value) {
    return moment(value).format("DD/MM/YYYY");
  } else return placeholderForNullOrUndefined;
};

export const timeFormat = (date?: any) => {
  return date && moment(date).isValid()
    ? moment(date).format("HH:mm")
    : placeholderForNullOrUndefined;
};

export const smartDateFormat = (date: Date | undefined) => {
  if (date == null) {
    return placeholderForNullOrUndefined;
  }
  const today = moment().format('DD/MM/YYYY');
  const yesterday = moment().subtract(1, "days").format('DD/MM/YYYY');
  const formattedDate = moment(date).format('DD/MM/YYYY');

  if (today == formattedDate) {
    return i18next.t('common.oggi');
  }
  if (yesterday == formattedDate) {
    return i18next.t('common.ieri');
  }

  return formattedDate;
};

export const dateTimeFormat = (date?: any) => {
  return date && moment(date).isValid()
    ? moment(date).format("DD/MM/YYYY - HH:mm")
    : placeholderForNullOrUndefined;
};

export const dateTimeFormatDivider: Function = (value: Date | string | undefined, divider: string) => {
  if (value instanceof Date) {
    const date = moment(value.toISOString()).format("DD/MM/YYYY");
    const time = moment(value.toISOString()).format("HH:mm");
    return `${date} - ${divider} ${time}`
  } else if (value) {
    const date = moment(value).format("DD/MM/YYYY");
    const time = moment(value).format("HH:mm");
    return `${date} - ${divider} ${time}`
  } else return placeholderForNullOrUndefined;
};

export const defaultCurrency = 'EUR';

export const currencyFormat = (num?: number, currency?: string, decimalCharacter?: number) => {
  return num != undefined
    ? (new Intl.NumberFormat("it-IT", {
      style: "decimal",
      minimumFractionDigits: decimalCharacter || 2,
      maximumFractionDigits: decimalCharacter || 2,
    }).format(num) + (currency ? (" " + currency) : ""))
    : placeholderForNullOrUndefined;
};

export const formattedNumberToNumber = (num: number | string | undefined) => {
  return num ? Number(String(num).replace(',', '.')) : 0;
};

export const stringNormalize = (field: string, maxChar?: number) => {
  let s: string = !!field ? field : placeholderForNullOrUndefined;
  if (maxChar) {
    s = s.length > maxChar ? `${s.substr(0, maxChar)}...` : s;
  }
  return s;
};

export const findInArrayRapporti = (
  array: RapportoCompletoDto[],
  id: number
): RapportoCompletoDto => {
  let rapportoSelezionato;
  let rapportoCompleto;
  if (array) {
    for (let i = 0; i < array.length; i++) {
      rapportoCompleto = array[i];
      if (rapportoCompleto.rapporto && rapportoCompleto.rapporto.id == id) {
        rapportoSelezionato = array[i];
        break;
      }
    }
  }
  return rapportoSelezionato || array[0];
};

export const findInArrayRapportiEstinti = (
  array: RapportoDto[],
  id: number
): RapportoDto => {
  let rapportoSelezionato;
  let rapportoCompleto;
  if (array) {
    for (let i = 0; i < array.length; i++) {
      rapportoCompleto = array[i];
      if (rapportoCompleto && rapportoCompleto.id == id) {
        rapportoSelezionato = array[i];
        break;
      }
    }
  }
  return rapportoSelezionato || array[0];
};

export const getDataRata = (giornoDellaRataCorrente?: number, meseDellaRataCorrente?: number) => {
  if (!giornoDellaRataCorrente || !meseDellaRataCorrente) {
    return placeholderForNullOrUndefined
  };
  return `${giornoDellaRataCorrente.toString().padStart(2, '0')}/${(meseDellaRataCorrente).toString().padStart(2, '0')}`;
};

export const formatRapporto = (r: RapportoCompletoDto) => {
  const descr = r.rapporto?.psd2 ? r.rapporto?.nomeBanca : (r.rapporto?.alias || r.rapporto?.intestazione);
  const iban = r.rapporto?.iban;
  return (`${!!descr ? descr + " - " : ""}${iban}`).trim();
};

export const getVeicoloDescription = (id: string): string => {
  let description = ""
  switch (id) {
    case "1":
      description = "Autoveicoli";
      break;
    case "3":
      description = "Ciclomotori";
      break;
    case "4":
      description = "Motoveicoli";
      break;
    case "7":
      description = "Quadriciclo";
      break;
    case "2":
      description = "Rimorchi";
      break;
  }
  return description;
};

export const getTotale = (a: any, parameter: string) => {
  const temp = a.filter((e: any) => {
    if (e[parameter]) return e;
  });
  const onlyValues = temp.map((el: any) => el[parameter]);

  return onlyValues.length > 0
    ? onlyValues.reduce((a: any, b: any) => {
      return String(Number(a.toString().replace(",", ".")) + Number(b.toString().replace(",", ".")));
    })
    : 0;
};

export const arrayIncludes = (array: Array<any>, key: string, value: string | number) => {
  let found = false;
  for (let i = 0; i < array.length; i++) {
    if (array[i][key] === value) {
      found = true;
      break;
    }
  }
  return found;
};

export interface InformazioniVeicolo {
  tipoVeicolo: string;
  targaVeicolo: string;
}

export const tipologie = {
  ONECLICK: "oneClick",
  BONIFICO: "bonifico"
}

export const getStatoRata = (value: RataFinanziamentoDtoStatoEnum) => {
  switch (value) {
    case RataFinanziamentoDtoStatoEnum.RESIDUA:
      return i18next.t(`piano_mutui.dashboard.stato.daPagare`);
    case RataFinanziamentoDtoStatoEnum.MORA:
      return i18next.t(`piano_mutui.dashboard.stato.inMora`);
    case RataFinanziamentoDtoStatoEnum.AVV:
      return i18next.t(`piano_mutui.dashboard.stato.avvisata`);
    case RataFinanziamentoDtoStatoEnum.PAGATA:
      return i18next.t(`piano_mutui.dashboard.stato.pagata`);
    case RataFinanziamentoDtoStatoEnum.ANT:
      return i18next.t(`piano_mutui.dashboard.stato.ant`);
    default:
      return ""
  }
};

export const getCausaleTitoli = (value: string) => {
  switch (value) {
    case "XH":
      return "Cedola titoli";
    case "XC":
    case "XD":
    case "XY":
      return "Rimborso";
    case "XQ":
      return "Dividendi titoli";
    default:
      return "";
  }
};

const getTipoPagamentoDaCodiceFunzione = (codiceFunzione: string): string => {
  const objs: { [key: string]: string } = {
    'BONIFICOSEPA': 'bonificoOrdinario',
    'BONIFICOFISCALE': 'bonificoFiscale',
    'BONIFICODEPOSITO': 'bonificoDeposito',
    'BONIFICORIPETITIVO': 'bonificoRipetitivo',
    'BONIFICOESTERO': 'bonificoEstero',
    'PAGMAV': 'mav',
    'PAGRAV': 'rav',
    'RICARICACELLULARE': 'ricaricheCellulare',
    'BOLLETTINOBANCARIO': 'freccia',
    'BOLLETTINOPOSTALE': 'bollettinoPostale',
    'BOLLETTINOBIANCO': 'bollettinoPostale',
    'BOLLETTINOPREMARCATO': 'bollettinoPostale',
    'SUDTIROLPASS': 'sudtirolPass',
    'PAGACI': 'bolloAci',
    'EBILLING': 'cbill',
    'CARTACONTODISPOCATEGORIE': 'cartaconto',
    'F24SEMPLIFICATO': 'semplificato',
    'F24STANDARD': 'standard',
    'F24ACCISE': 'accise',
    'F24IDENTIFICATIVI': 'identificativi'
  };
  return objs[codiceFunzione];
};

export const actionExportHelper = (action: Function, codiceFunzione: string, disposizione: any, sottoTipo?: string): void => {
  const criteriRicercaRequestIn: DatiPagamentoDto = {
    codiceRapporto: disposizione.codiceRapporto || disposizione.contoAddebito || disposizione.ordinante?.contoAddebito,
    tipo: getTipoPagamentoDaCodiceFunzione(codiceFunzione.toUpperCase()),
    // identificativoPagamento: String(disposizione.id),
    sottoTipo: sottoTipo,
    dettaglio: {
      tipoDisposizione: codiceFunzione,
      disposizione: disposizione
    }
  };
  action(
    criteriRicercaRequestIn
  );
};

export const fireWindowResize = () => {
  if (typeof (Event) === 'function') {
    // modern browsers
    window.dispatchEvent(new Event('resize'));
  } else {
    // ie
    var evt = window.document.createEvent('UIEvents');
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
  }
};

export const base64ToImageSrc = (src: string | undefined): string => {
  if (!src) {
    return '';
  }
  return `data:image/png;base64,${src}`;
};

export const base64ToImage = (base64: string, contentType: string): string => {
  return `data:${contentType};base64,${base64}`;
};

export const servizioSelected = (list: Array<any>, key: string) => {
  let selected;
  let servizio;
  if (list) {
    for (let i = 0; i < list.length; i++) {
      servizio = list[i];
      if (servizio[key] === true) {
        selected = servizio.iban;
        break;
      }
    }
  }
  return selected;
};

export const getResources = (resources: ResourcesDto) => {
  let resourcesObejct = {};
  const translations = Object.entries(resources);
  for (const [key, value] of Object.entries(translations)) {
    const keyName = value[0];
    const obj = {
      [keyName]: {
        translation: value[1]
      }
    };
    Object.assign(resourcesObejct, obj);
  }
  return resourcesObejct;
};

export const getTipoVariazioneCanaleFromServizion = (servizio: number) => {
  return i18next.t(`variazione_canali.tipi.${servizio}`);
};

export const downloadPDFFromMessaggio = (messaggio: MessaggioAllegatoDto): void => {
  const pdf = messaggio?.data;
  if (pdf) {
    const binary = atob(pdf.replace(/\s/g, ''));
    const length = binary.length;
    const buffer = new ArrayBuffer(length);
    let file = new Uint8Array(buffer);
    for (let i = 0; i < length; i++) {
      file[i] = binary.charCodeAt(i);
    }

    const blob = new Blob([file], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${messaggio?.name || "Termini_e_condizioni.pdf"}`;
    link.click();
  }
};

export const openPDFFromMessaggio = (messaggio: MessaggioAllegatoDto, callbackPopUpBlocked: (url: string) => void): void => {
  const pdf = messaggio?.data;
  if (pdf) {
    const binary = atob(pdf.replace(/\s/g, ''));
    const length = binary.length;
    const buffer = new ArrayBuffer(length);
    let file = new Uint8Array(buffer);
    for (let i = 0; i < length; i++) {
      file[i] = binary.charCodeAt(i);
    }

    const blob = new Blob([file], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    const newWin = window.open(url, '_blank');
    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
      callbackPopUpBlocked(url);
      // Pop-up blocked, showing an application pop-up that requests the user to click on the pop-up
      console.log('newWin blocked', newWin);
    }
  }
};

export const buildErrorMessage = (error: IGeneralError | null, t: Function): string => {
  if (!error) {
    return "";
  } else {
    const prefix = "common.errors.";
    let errorMessage = '';
    if (error?.fieldValidationErrors?.length) {
      error.fieldValidationErrors.forEach((e) => {
        errorMessage += '\n- ' + t(e.errorMessage);
      });
    } else if (error?.message) {
      const erroreTradotto = error.message.indexOf(' ') > -1 ?
        error.message :
        t((error.message.startsWith(prefix) ? '' : prefix) + error.message);
      errorMessage += erroreTradotto;
      // se l'errore tradotto è diverso dalla chiave, allora stampo anche la chiave tra parentesi, utile per risalire all'errore
      // if (erroreTradotto != error.message) {
      //   errorMessage += '\n(' + error.message.replace(prefix, "") + ')';
      // }
      // if (error?.details) {
      //   errorMessage += `\n - ${t("common.errors.dettagli")} : ${error?.details?.indexOf(' ') > -1 ?
      //     error?.details :
      //     t((error.details.startsWith(prefix) ? '' : prefix) + error?.details)}`;
      // }
    }
    return errorMessage || t(`common.errors.fallback`);
  }
};

export const getCustomError = (titolo: string, dettaglio?: string, status?: number) => {
  return {
    timestamp: moment().toISOString(),
    message: titolo,
    details: dettaglio,
    error: titolo,
    status: status
  };
};

/**
 * 
 * @param num number to round (ex: 1500 -> 1.5K, 2200 -> 2K)
 */
export const roundThousands = (num: number) => {
  const roundedNumber = Math.round(num / 500) * 500;
  return roundedNumber === 0 ? 0 : roundedNumber / 1000
};

/**
 * 
 * @param file file to be converted to base64
 * @description transform a type file to a base64
 */
export async function getBase64(file: any) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  return new Promise((res, rej) => {
    reader.onloadend = () => res(reader.result);
  });
};

/**
 * 
 * @param src source file URL
 */
export const openPDF = (src: string) => {
  const link = document.createElement('a');
  link.href = src;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const parseAccountCode = function (str: string): string {
  let ndg = '';
  if (str.match(/[\-]/)) {
    let sn = str.split('-');
    ndg = sn[0];
  }
  let i = 0;
  const chars = [ndg];
  while (chars[i] == '0') {
    i++;
    ndg = ndg.substring(1, ndg.length);
  }
  return ndg;
};

export const calcolaPrezzoDaVisualizzare = (prezzoLimite: number | undefined, prezzoStop: number | undefined) => {
  if (!prezzoLimite && !prezzoStop) {
    return i18next.t('storicoOrdine.prezzoOrdine.alMeglio');
  } else {
    return currencyFormat(prezzoLimite !== 0 ? prezzoLimite : prezzoStop, '', 5);
  }
};

export const mapF24LookupDtosToItems = (f24lookupDtos: F24LookupDto[] | undefined): Option[] => {
  if (!f24lookupDtos) {
    return [];
  }
  return f24lookupDtos.map(el => {
    return { key: el.codice || '', description: el.desc || '' };
  });
};

export const generaData = (giorno?: string, mese?: string, anno?: string) => {
  let data = new Date();
  data.setFullYear(Number(anno), Number(mese) - 1, Number(giorno));
  return dateFormat(data);
};

export const formatWithPaddingLeft = (x: number | undefined, maxLength: number, fillString: string): string => {
  if (x != undefined) {
    return x.toString().padStart(maxLength, fillString);
  }
  return placeholderForNullOrUndefined;
};

export const getQueryParams = (params: string, url: string) => {
  let href = url;
  // this is an expression to get query strings
  let regexp = new RegExp('[?&]' + params + '=([^&#]*)', 'i');
  let qString = regexp.exec(href);
  return qString ? qString[1] : undefined;
};

export const dayOfWeek = (date: any, t: Function) => {
  return date ? t('common.weekDays.' + moment(date).day()) : placeholderForNullOrUndefined;
};

export const dateFormatIntervalloConsulenza = (dataDaString: string, dataAString: string, t: Function) => {
  const [dataDa, oraDa] = dataDaString.split(' ');
  const [, mese, giorno] = dataDa.split('-');
  const [oreDa, minutiDa] = oraDa.split(':');
  const [, oraA] = dataAString.split(' ');
  const [oreA, minutiA] = oraA.split(':');
  return dayOfWeek(dataDa, t) + ' ' + giorno + ' ' + t(`common.months.${Number(mese)}`) + `, ${t('common.ore')} ` + oreDa + ':' + minutiDa + ' - ' + oreA + ':' + minutiA;
};

export const dateFormatExtended = (dateString: any, t: Function) => {
  if (!dateString) return placeholderForNullOrUndefined;
  const dateSplitted = dateString.split(" ")[0].split("-");
  const date = new Date(Number(dateSplitted[0]), Number(dateSplitted[1]) - 1, Number(dateSplitted[2]));
  return date
    ? (dayOfWeek(date, t) + ' ' + date.getDate() + ' ' + t(`common.months.${date.getMonth() + 1}`) + ' ' + moment(date).format('YYYY'))
    : placeholderForNullOrUndefined;
};

export const calcolaSecondiDiDifferenza = (data1: Moment, data2: Moment) => {
  // abilita chiamata 5 minuti prima dell'orario
  if (data2.diff(data1, 'seconds') >= -300) {
    return true;
  } else {
    return false;
  }
};

export const fromUpperCaseToCapitalLetter = (text?: string) => {
  if (!!text) {
    const textLowerCase = text.toLowerCase();
    return textLowerCase.charAt(0).toUpperCase() + textLowerCase.slice(1);
  } else {
    return '-';
  }
};

export const buildGenericItemSelect = (t?: Function, items?: Items[], prefix?: string): Array<Item> => {

  if (items) {
    return items.map(e => ({
      key: e.chiave,
      description: t && prefix ? t(`${prefix}.${e.chiave}`) : e.valore
    }));
  } else {
    return [];
  }
};

export const sendEventToMatomo = (funzionalita: string, azione: string, nome?: string, value?: number) => {
  piwik.push(['trackEvent', funzionalita, azione, [nome], [value]]);
};

export const matomoTrackStep = (page: string, step: number) => {
  piwik.push(['trackPageView', `${selectedAbi == '06045' ? 'Sparkasse' : 'CiviBank'} ON / ${page}/Step${step}`]);
};

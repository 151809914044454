"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CriteriRicercaMovimentiRequestInOrdinamentoEnum = exports.CriteriRicercaMovimentiRequestInSegnoEnum = exports.CriteriRicercaMovimentiRequestInToJSON = exports.CriteriRicercaMovimentiRequestInFromJSONTyped = exports.CriteriRicercaMovimentiRequestInFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CriteriRicercaMovimentiRequestInFromJSON(json) {
  return CriteriRicercaMovimentiRequestInFromJSONTyped(json, false);
}
exports.CriteriRicercaMovimentiRequestInFromJSON = CriteriRicercaMovimentiRequestInFromJSON;
function CriteriRicercaMovimentiRequestInFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceRapporto': json['codiceRapporto'],
    'dataIniziale': new Date(json['dataIniziale']),
    'dataFinale': new Date(json['dataFinale']),
    'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
    'gruppi': !runtime_1.exists(json, 'gruppi') ? undefined : json['gruppi'],
    'keyword': !runtime_1.exists(json, 'keyword') ? undefined : json['keyword'],
    'importoDa': !runtime_1.exists(json, 'importoDa') ? undefined : json['importoDa'],
    'importoA': !runtime_1.exists(json, 'importoA') ? undefined : json['importoA'],
    'caricaSoloMovimenti': !runtime_1.exists(json, 'caricaSoloMovimenti') ? undefined : json['caricaSoloMovimenti'],
    'segno': !runtime_1.exists(json, 'segno') ? undefined : json['segno'],
    'ordinamento': !runtime_1.exists(json, 'ordinamento') ? undefined : json['ordinamento'],
    'cartaconto': !runtime_1.exists(json, 'cartaconto') ? undefined : json['cartaconto'],
    'tags': !runtime_1.exists(json, 'tags') ? undefined : json['tags'],
    'offset': !runtime_1.exists(json, 'offset') ? undefined : json['offset'],
    'categoriePfm': !runtime_1.exists(json, 'categoriePfm') ? undefined : json['categoriePfm'],
    'criteriPsd2': !runtime_1.exists(json, 'criteriPsd2') ? undefined : _1.CriteriMovimentiPsd2InFromJSON(json['criteriPsd2']),
    'contoDeposito': !runtime_1.exists(json, 'contoDeposito') ? undefined : json['contoDeposito'],
    'saldoContabile': !runtime_1.exists(json, 'saldoContabile') ? undefined : json['saldoContabile'],
    'saldoDisponibile': !runtime_1.exists(json, 'saldoDisponibile') ? undefined : json['saldoDisponibile']
  };
}
exports.CriteriRicercaMovimentiRequestInFromJSONTyped = CriteriRicercaMovimentiRequestInFromJSONTyped;
function CriteriRicercaMovimentiRequestInToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceRapporto': value.codiceRapporto,
    'dataIniziale': value.dataIniziale.toISOString(),
    'dataFinale': value.dataFinale.toISOString(),
    'divisa': value.divisa,
    'gruppi': value.gruppi,
    'keyword': value.keyword,
    'importoDa': value.importoDa,
    'importoA': value.importoA,
    'caricaSoloMovimenti': value.caricaSoloMovimenti,
    'segno': value.segno,
    'ordinamento': value.ordinamento,
    'cartaconto': value.cartaconto,
    'tags': value.tags,
    'offset': value.offset,
    'categoriePfm': value.categoriePfm,
    'criteriPsd2': _1.CriteriMovimentiPsd2InToJSON(value.criteriPsd2),
    'contoDeposito': value.contoDeposito,
    'saldoContabile': value.saldoContabile,
    'saldoDisponibile': value.saldoDisponibile
  };
}
exports.CriteriRicercaMovimentiRequestInToJSON = CriteriRicercaMovimentiRequestInToJSON;
/**
* @export
* @enum {string}
*/
var CriteriRicercaMovimentiRequestInSegnoEnum;
(function (CriteriRicercaMovimentiRequestInSegnoEnum) {
  CriteriRicercaMovimentiRequestInSegnoEnum["A"] = "A";
  CriteriRicercaMovimentiRequestInSegnoEnum["D"] = "D";
})(CriteriRicercaMovimentiRequestInSegnoEnum = exports.CriteriRicercaMovimentiRequestInSegnoEnum || (exports.CriteriRicercaMovimentiRequestInSegnoEnum = {}));
/**
* @export
* @enum {string}
*/
var CriteriRicercaMovimentiRequestInOrdinamentoEnum;
(function (CriteriRicercaMovimentiRequestInOrdinamentoEnum) {
  CriteriRicercaMovimentiRequestInOrdinamentoEnum["A"] = "A";
  CriteriRicercaMovimentiRequestInOrdinamentoEnum["D"] = "D";
})(CriteriRicercaMovimentiRequestInOrdinamentoEnum = exports.CriteriRicercaMovimentiRequestInOrdinamentoEnum || (exports.CriteriRicercaMovimentiRequestInOrdinamentoEnum = {}));
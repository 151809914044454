import { GetAccettazioniFunzioneEnum as CodiceFunzione } from '@sparkasse/commons';
import { createBrowserHistory as createHistory } from "history";

export const history = createHistory();

export const loginUrl = "/";
export const logoutUrl = "/logout";

// TODO 
// Refactor per automatizzare App.tsx, SwitchPage.tsx e tutti gli history.push
export const WEBPAGE = {
  Anatocismo: { url: "/anatocismo", menu: "menu.addebito_interessi", alias: [CodiceFunzione.ANATOCISMO] },
  ArchivioBozze: { url: "/archivio-bozze", menu: "menu.archivio_bozze", alias: [] },
  Assegni: { url: "/assegni", menu: "menu.assegni", alias: ['ASSEGNIESTEROSITUAZIONE', 'ASSEGNIITALIASITUAZIONE'] },
  BancomatPay: { url: "/bancomat-pay", menu: "menu.bancomat_pay", alias: [CodiceFunzione.JIFFYACCOUNT] },
  BancomatPayAttivazione: { url: "/bancomat-pay-attivazione", menu: "menu.bancomat_pay", alias: [] },
  BancomatPayDisattiva: { url: "/bancomat-pay-disattiva", menu: "", alias: [] },
  BancomatPayGestioneRapporti: { url: "/bancomat-pay-gestione-rapporti", menu: "", alias: [] },
  BancomatPayMassimali: { url: "/bancomat-pay-massimali", menu: "", alias: [] },
  Bilancio: { url: "/bilancio", menu: "menu.bilancio", alias: [] },
  BloccoCarte: { url: "/blocco-carte", menu: "menu.blocco_carte", alias: [] },
  BloccoUtente: { url: "/blocco-utente", menu: "menu.blocco_utente", alias: [] },
  BollettinoBancarioFreccia: { url: "/bollettino-bancario-freccia", menu: "menu.bollettino_bancario_freccia", alias: [CodiceFunzione.BOLLETTINOBANCARIO] },
  BollettinoBianco: { url: "/bollettino-bianco", menu: "menu.bollettino_postale_bianco", alias: ["bollettinopostale", CodiceFunzione.BOLLETTINOPOSTALEBIANCO, "menu.bollettino_postale_bianco"] },
  BollettinoPremarcato: { url: "/bollettino-premarcato", menu: "menu.bollettino_postale_premarcato", alias: ["bollettinopostale", CodiceFunzione.BOLLETTINOPOSTALEPREMARCATO, "menu.bollettino_postale_premarcato"] },
  BolloAci: { url: "/bollo-aci", menu: "menu.bollo_aci", alias: [] },
  BonificiRipetitivi: { url: "/bonifici-ripetitivi", menu: "menu.archivio_ripetitivi", alias: [] },
  BonificoDeposito: { url: "/bonifico-deposito", menu: "menu.bonifico_conto_deposito", alias: ['BONIFICODEPOSITOELENCO', CodiceFunzione.BONIFICODEPOSITO] },
  BonificoDetrazioneFiscale: { url: "/bonifico-detrazione-fiscale", menu: "menu.bonifico_detrazioni_fiscali", alias: [CodiceFunzione.BONIFICOFISCALE] },
  BonificoEstero: { url: "/bonifico-estero", menu: "menu.bonifico_estero", alias: [CodiceFunzione.BONIFICOESTERO] },
  BonificoMyBank: { url: "/bonifico-my-bank", menu: "", alias: [] },
  BonificoRipetitivo: { url: "/bonifico-ripetitivo", menu: "menu.bonifico_ripetitivo", alias: [CodiceFunzione.BONIFICORIPETITIVO, "bonificoripetitivo, bonificoperiodico"] },
  BonificoSepa: { url: "/bonifico-sepa", menu: "menu.bonifico_sepa", alias: [CodiceFunzione.BONIFICOSEPA, "bonificosepa", "bonifico", "menu.bonifico_sepa"] },
  CartaCredito: { url: "/carta-credito", menu: "menu.carta_di_credito", alias: [] },
  Cbill: { url: "/cbill", menu: "menu.cbill", alias: [CodiceFunzione.EBILLING] },
  CercaTitoliPreferiti: { url: "/cerca-titoli-preferiti", menu: "menu.titoli_preferiti", alias: [] },
  CercaTitolo: { url: "/cerca-titolo", menu: "menu.cerca_titoli", alias: [CodiceFunzione.TRADINGNEW, "menu.compra", "menu.compravendita"] },
  ConsensiRevoca: { url: "/consensi-revoca", menu: "", alias: [] },
  ConsensiTerzeParti: { url: "/consensi-terze-parti", menu: "menu.consensi_terze_parti", alias: [] },
  Contratti: { url: "/contratti", menu: "menu.contratti", alias: [] },
  DatiFiliale: { url: "/dati-filiale", menu: "menu.la_tua_banca", alias: [] },
  DatiPersonali: { url: "/dati-personali", menu: "menu.i_tuoi_dati", alias: ["anagrafiche"] },
  ModificaContatti: { url: "/modifica-contatti", menu: "", alias: [""] },
  Dettaglio: { url: "/dettaglio", menu: "", alias: [] },
  DettaglioRapporto: { url: "/dettaglio-rapporto", menu: "", alias: [] },
  Documenti: { url: "/documenti", menu: "menu.documenti", alias: [] },
  ElencoRapporti: { url: "/elenco-rapporti", menu: "menu.elenco_rapporti", alias: [] },
  ElencoSmartcard: { url: "/elenco-smartcard", menu: "menu.carta_conto", alias: ["ESTRATTOCONTOCATEGORIE", "CARTACONTO"] },
  EstinguiTimeDeposit: { url: "/estingui-time-deposit", menu: "", alias: [] },
  F24Accise: { url: "/f24-accise", menu: "menu.f24_accise", alias: [CodiceFunzione.F24ACCISE] },
  F24Elide: { url: "/f24-elide", menu: "menu.f24_elide", alias: [CodiceFunzione.F24ACCISE] },
  F24Ordinario: { url: "/f24-ordinario", menu: "menu.f24_ordinario", alias: [CodiceFunzione.F24STANDARD] },
  F24Semplificato: { url: "/f24-semplificato", menu: "menu.f24_semplificato", alias: [CodiceFunzione.F24SEMPLIFICATO, "menu.f24"] },
  GestioneCredenziali: { url: "/gestione-credenziali", menu: "menu.gestione_credenziali", alias: [] },
  Home: { url: "/home", menu: "", alias: ["HOME"] },
  ImpostaAlias: { url: "/imposta-alias", menu: "", alias: [] },
  LibrettiRisparmio: { url: "/libretti-risparmio", menu: "menu.libretti_risparmio", alias: ["DEPOSITIRISPARMIO"] },
  MandatiSdd: { url: "/mandati-sdd", menu: "menu.addebiti_diretti_sdd", alias: ['MANDATISDDINFO', 'MANDATISDDINFOCAT'] },
  MassimaliUtente: { url: "/massimali-utente", menu: "", alias: [] },
  Mav: { url: "/mav", menu: "menu.mav", alias: ["pagmav"] },
  ModificaBonificoRipetitivo: { url: "/modifica-bonifico-ripetitivo", menu: "", alias: [] },
  Mercato: { url: "/mercato", menu: "menu.mercato", alias: ["mercato", "quotazioni"] },
  ModificaPassword: { url: "/modifica-password", menu: "", alias: [] },
  ModificaPin: { url: "/modifica-pin", menu: "", alias: [] },
  ModificaResidenza: { url: "/modifica-residenza", menu: "", alias: [] },
  NuovoTimeDeposit: { url: "/nuovo-time-deposit", menu: "", alias: [] },
  OperazioniOneClick: { url: "/operazioni-one-click", menu: "menu.oneclick", alias: [CodiceFunzione.ONECLICK] },
  PagamentiEffettuati: { url: "/pagamenti-effettuati", menu: "menu.pagamenti_effettuati", alias: [CodiceFunzione.ELENCOPAGAMENTI, "menu.pagamenti_effettuati"] },
  DettaglioPagamento: { url: "/dettaglio-pagamento", menu: "", alias: [] },
  PagamentiEffettuatiRapportiEstinti: { url: "/rapporti-estinti", menu: "", alias: [] },
  DettaglioPagamentoRapportiEstinti: { url: "/dettaglio-pagamento-estinti", menu: "", alias: [] },
  PianoMutui: { url: "/piano-mutui", menu: "menu.mutui", alias: [CodiceFunzione.MUTUI, "mutui", "menu.piano_mutuo"] },
  PianoPrestiti: { url: "/piano-prestiti", menu: "menu.prestito_green", alias: [] },
  Polizze: { url: "/polizze", menu: "menu.polizze", alias: ["POLIZZE", "POLIZZEBANCASS"] },
  PostLoginMessages: { url: "/post-login-messages", menu: "", alias: [] },
  RapportiPredefiniti: { url: "/rapporti-predefiniti", menu: "", alias: [] },
  Rav: { url: "/rav", menu: "menu.rav", alias: ["pagrav"] },
  RevocaBollettinoBianco: { url: "/revoca-bollettino-bianco", menu: "", alias: [] },
  RevocaBollettinoFreccia: { url: "/revoca-bollettino-freccia", menu: "", alias: [] },
  RevocaBollettinoPremarcato: { url: "/revoca-bollettino-premarcato", menu: "", alias: [] },
  RevocaBonificoDeposito: { url: "/revoca-bonifico-deposito", menu: "", alias: [] },
  RevocaBonificoDetrazioneFiscale: { url: "/revoca-bonifico-detrazione-fiscale", menu: "", alias: [] },
  RevocaBonificoRipetitivo: { url: "/revoca-bonifico-ripetitivo", menu: "", alias: [] },
  RevocaBonificoSepa: { url: "/revoca-bonifico-sepa", menu: "", alias: [] },
  RevocaF24Accise: { url: "/revoca-f24-accise", menu: "", alias: [] },
  RevocaF24Elide: { url: "/revoca-f24-elide", menu: "", alias: [] },
  RevocaF24Ordinario: { url: "/revoca-f24-ordinario", menu: "", alias: [] },
  RevocaF24Semplificato: { url: "/revoca-f24-semplificato", menu: "", alias: [] },
  RevocaMav: { url: "/revoca-mav", menu: "", alias: [] },
  RevocaRav: { url: "/revoca-rav", menu: "", alias: [] },
  RiattivaBonificoRipetitivo: { url: "/riattiva-bonifico-ripetitivo", menu: "", alias: [] },
  RicaricaAltoAdigePass: { url: "/ricarica-alto-adige-pass", menu: "menu.altoadige_pass", alias: [CodiceFunzione.SUDTIROLPASS] },
  RicaricaSmartcard: { url: "/ricarica-smartcard", menu: "menu.ricarica_carta_conto", alias: [CodiceFunzione.CARTACONTODISPOCATEGORIE, CodiceFunzione.CARTACONTODISPO] },
  RicaricaTelefonica: { url: "/ricarica-telefonica", menu: "menu.ricarica_telefonica", alias: [CodiceFunzione.RICARICACELLULARE] },
  RicevuteBancarie: { url: "/ricevute-bancarie", menu: "menu.riba", alias: [CodiceFunzione.PAGEFFETTI] },
  RubricaBeneficiari: { url: "/rubrica-beneficiari", menu: "menu.rubrica_beneficiari", alias: ["RUBRICA"] },
  SaldoEMovimenti: { url: "/saldo-e-movimenti", menu: "menu.saldo_e_movimenti", alias: [CodiceFunzione.ESTRATTOCONTOITALIA] },
  SaldoEMovimentiDeposito: { url: "/saldo-e-movimenti-deposito", menu: "menu.movimenti_conto_deposito", alias: ["BONIFICODEPOSITOELENCO"] },
  ScadenzaTitoli: { url: "/scadenza-titoli", menu: "menu.flussi_attesi", alias: ["menu.scadenza_titoli"] },
  SospendiBonificoRipetitivo: { url: "/sospendi-bonifico-ripetitivo", menu: "", alias: [] },
  TimeDeposit: { url: "/time-deposit", menu: "menu.elenco_time_deposit", alias: ["TIMEDEPOSITINFO", "menu.time_deposi"] },
  VariazioneCanali: { url: "/variazione-canali", menu: "menu.variazione_canali", alias: [CodiceFunzione.COMUNICAZIONICANALE] },
  NotificePush: { url: "/notifica-push", menu: "menu.poc_notifica_push", alias: [] },
  Patrimonio: { url: "/patrimonio", menu: "menu.riepilogo_patrimonio", alias: [] },
  Faq: { url: "/faq", menu: "menu.faq", alias: [] },
  TimeDepositBox: { url: "/time-deposit-box", menu: "menu.time_deposit_box", alias: [] },
  ContattiFilialeVirtuale: { url: "/contatti-filiale-virtuale", menu: "menu.contatti", alias: ["menu.contattaci"] },
  StoricoOrdine: { url: "/storico-ordine", menu: "menu.storico_ordini", alias: [] },
  DettaglioOrdine: { url: "/dettaglio-ordine", menu: "", alias: [] },
  EstinguiStoricoOrdine: { url: "/estingui-storico-ordine", menu: "", alias: [] },
  NuovaRichiestaFilialeVirtuale: { url: "/nuova-richiesta-filiale-virtuale", menu: "", alias: [] },
  GestionePolizze: { url: "/gestione-polizze", menu: "menu.neosurance_polizze_digital", alias: [] },
  GestionePolizzeAquisto: { url: "/gestione-polizze-aquisto", menu: "menu.neosurance_acquisto_polizza", alias: [] },
  PrestitoCofidis: { url: "/prestito-cofidis", menu: "menu.prestito_idea", alias: [] },
  PrestitoNuovo: { url: "/prestito-nuovo", menu: "", alias: [] },
  RichiediFidoIsiDispo: { url: "/richiedi-fido-isi-dispo", menu: "menu.fido_isi_dispo", alias: [] },
  AcquistaTitolo: { url: "/acquista-titolo", menu: "", alias: [CodiceFunzione.TRADINGNEW] },
  VenditaTitolo: { url: "/vendita-titolo", menu: "", alias: [CodiceFunzione.TRADINGNEW] },
  ElencoTributiLocali: { url: "/elenco-tributi-locali", menu: "menu.tributi_locali_elenco", alias: [CodiceFunzione.PAGTRIBUTI] },
  ElencoPagamentiContoTerzi: { url: "/elenco-tributi-conto-terzi", menu: "menu.tributi_locali_terzi", alias: [] },
  Rendimenti: { url: "/analisi-rendimenti", menu: "menu.rendimenti", alias: ["RENDIMENTI"] },
  DettaglioTributiLocali: { url: "/dettaglio-tributi-locali", menu: "", alias: [] },
  PctSottoscrizione: { url: "/pct-sottoscrizione", menu: "menu.pronti_contro_termine", alias: [] },
  PolizzeInvestimento: { url: "/polizze-investimento", menu: "menu.polizze_investimento", alias: [] },
  CatalogoProdotti: { url: "/catalogo-prodotti", menu: "menu.per_te", alias: ['PERTE'] },
  DettaglioCatalogoProdotti: { url: "/dettaglio-prodotto-catalogo", menu: "", alias: [] },
  DossierTitoli: { url: "/dossier-titoli", menu: "menu.portafoglio_titoli", alias: ["DOSSIER"] },
  FirmaProposte: { url: "/firma-proposte", menu: "menu.firma_proposte", alias: ['FIRMADIGITALE'] },
  LaTuaBanca: { url: "/dati-filiale", menu: "menu.la_tua_banca", alias: [] },
  LimitiOperativi: { url: "/massimali-utente", menu: "menu.limiti_operativi", alias: [] },
  RicercaAtm: { url: "/", menu: "menu.ricerca_atm", alias: [] }, // missing ad oggi - assistenza.tabs.tsx
  NuovoContatto: { url: "/aggiungi-contatto", menu: "", alias: [] },
  DettaglioContatto: { url: "/dettaglio-contatto", menu: "", alias: [] },
  ModificaContatto: { url: "/modifica-contatto", menu: "", alias: [] },
  GestionePolizzeAquistoPet: { url: "/gestione-polizze-aquisto-pet", menu: "menu.neosurance_acquisto_polizzaPET", alias: [] },
  GestionePolizzeAquistoTravel: { url: "/gestione-polizze-aquisto-travel", menu: "menu.neosurance_acquisto_polizzaTravel", alias: [] },
  Mifid: { url: "/mifid", menu: "menu.questionario_mifid", alias: ['MIFID'] },
  MifidAggiornamento: { url: "/aggiornamento-mifid", menu: "", alias: [] },
  ElencoAppuntamenti: { url: "/elenco-appuntamenti", menu: "menu.appuntamenti", alias: [] },
  NuovoAppuntamento: { url: "/nuovo-appuntamento", menu: "", alias: [] },
  GestioneAppuntamento: { url: "/gestione-appuntamento", menu: "", alias: [] },
  FondiPaperless: { url: "/fondi-paperless", menu: "menu.variazione_canali_fondi", alias: [] },
  Logout: { url: "/logout", menu: "", alias: [] },
  QuestionarioAdeguataVerifica: { url: "/questionario-adeguata-verifica", menu: "menu.questionario_adeguata_verifica", alias: [] },
  AggiornaQuestionarioAdeguataVerifica: { url: "/aggiorna-questionario-adeguata-verifica", menu: "", alias: [] },
  AggiornaDocumento: { url: "/aggiorna-documento", menu: "", alias: [] },
  ElencoRichiesteFilialeVirtualeView: { url: "/elenco-richieste-filiale-virtuale", menu: "menu.richieste", alias: [] },
  ElencoUltimiAccessi: { url: "/elenco-ultimi-accessi", menu: "menu.ultimi_accessi", alias: [] },
  ZainettoFiscale: { url: "/zainetto-fiscale", menu: "menu.zainetto_fiscale", alias: [] },
  CartaRicaricabile: { url: "/carta-ricaricabile", menu: "menu.ricarica_civipay", alias: [] },
  CartaRicaricabileMovimenti: { url: "/carta-ricaricabile-movimenti", menu: "menu.carta_civipay", alias: [] },
  CartaNexiDebit: { url: "/carta-nexi-debit", menu: "menu.carta_nexi_debit", alias: [] },
  AcquistoNexiDebit: { url: "/acquisto-nexi-debit", menu: "menu.acquisto_nexi_debit", alias: [] }

  // OnboardingAdmin: { url: "/onboarding-admin", menu: "", alias: [] }
};

export const findPage = (page: string) => {
  const _mPage = page;//.toLowerCase();
  const _page = Object.values(WEBPAGE).find(p => p.url === _mPage || p.menu === _mPage || p.alias.some(a => a === _mPage));
  return _page;
}

export const navigateToPage = (page: string, state?: any) => {
  const _page = findPage(page);
  if (!!_page?.url) {
    if (!!state) {
      history.push({
        pathname: _page.url,
        state: state,
      });
    } else {
      history.push(_page.url);
    }
  }
}

export const navigateToLogout = () => {
  history.replace(logoutUrl);
}

export const navigateToLogin = () => {
  history.replace(loginUrl);
}

export const getCurrentPathAlias = () => {
  const _page = Object.values(WEBPAGE).find(p => p.url === history.location.pathname);
  return _page?.alias?.[0] || undefined;
}

export const getCurrentPath = () => {
  return history.location.pathname;
}

export default history;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetTimeDeposit = exports.resetTimeDepositCedole = exports.getPianoCedole = exports.resetRevocaTimeDeposit = exports.setTimeDepositSetStepRevoca = exports.resetListiniTimeDeposit = exports.esportaDettaglioTimeDepositPDF = exports.esportaElencoTimeDepositExcel = exports.esportaElencoTimeDepositPDF = exports.getDettaglioTimeDeposit = exports.autorizzaRevocaTimeDeposit = exports.simulaRevocaTimeDeposit = exports.getInfoAutenticazioneRevocaTimeDeposit = exports.setTimeDepositResetRiepilogo = exports.setTimeDepositResetStep = exports.setTimeDepositSetStep = exports.setTimeDepositResetForm = exports.setTimeDepositSetImportoDeposito = exports.setTimeDepositSetListinoSelezionato = exports.autorizzaTimeDeposit = exports.simulaAperturaTimeDeposit = exports.getInfoAutenticazioneTimeDeposit = exports.getListiniTimeDeposit = exports.setTimeDepositSetPeriodo = exports.resetTimeDepositSelezionato = exports.resetFiltriTimeDepositBox = exports.resetFiltriTimeDeposit = exports.setTimeDepositSetDataScadenzaA = exports.setTimeDepositSetDataScadenzaDa = exports.setTimeDepositSetDataDecorrenzaA = exports.setTimeDepositSetDataDecorrenzaDa = exports.setTimeDepositSetStato = exports.setTimeDepositSetRapportoSelezionato = exports.getTimeDepositSingleList = exports.getTimeDeposit = exports.getParametriTimeDepositBox = exports.getParametriTimeDeposit = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_1 = require("../general");
const general_configuration_1 = require("../general/general.configuration");
const general_types_1 = require("../general/general.types");
const helpers_1 = require("../helpers");
const timeDeposit_selectors_1 = require("./timeDeposit.selectors");
/* get parametri time deposit - START */
function getParametriTimeDepositStart() {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_GET_PARAMETRI_START
  };
}
function getParametriTimeDepositSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_GET_PARAMETRI_SUCCESS,
    payload
  };
}
function generalError(value, showGenericModalError, sendErrorToMatomo, dispositiva, nomeEvento) {
  return async dispatch => {
    dispatch((0, general_1.setError)(value, showGenericModalError));
    !!dispositiva && !!sendErrorToMatomo && sendErrorToMatomo(dispositiva, "codeError ".concat(dispositiva, " ").concat(value.status, " / ").concat(value === null || value === void 0 ? void 0 : value.message, " / ").concat(value === null || value === void 0 ? void 0 : value.timestamp), nomeEvento);
  };
}
const getParametriTimeDeposit = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_1.setLoader)(true));
  dispatch(getParametriTimeDepositStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/assegni_parametri.json')).then(parametri => {
      dispatch(getParametriTimeDepositSuccess((0, api_rest_1.ParametriTimeDepositDtoFromJSON)(parametri)));
    });
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.TimeDepositControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriTimeDeposit().then(response => {
        var _a;
        dispatch(getParametriTimeDepositSuccess(response));
        ((_a = response.rapportiAcquisto) === null || _a === void 0 ? void 0 : _a.length) === 0 && dispatch((0, general_1.setNoRapporti)({
          type: general_types_1.TypeNoRapporti.DEPOBOX
        }));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.getParametriTimeDeposit = getParametriTimeDeposit;
/* get parametri time deposit - FINE */
/* get parametri time deposit Box - START */
function getParametriTimeDepositBoxStart() {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_GET_PARAMETRI_BOX_START
  };
}
function getParametriTimeDepositBoxSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_GET_PARAMETRI_BOX_SUCCESS,
    payload
  };
}
const getParametriTimeDepositBox = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_1.setLoader)(true));
  dispatch(getParametriTimeDepositBoxStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/assegni_parametri.json')).then(parametri => {
      dispatch(getParametriTimeDepositBoxSuccess((0, api_rest_1.ParametriTimeDepositDtoFromJSON)(parametri)));
    });
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.TimeDepositControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriTimeDepositBox().then(response => {
        var _a;
        dispatch(getParametriTimeDepositBoxSuccess(response));
        ((_a = response.rapportiInfo) === null || _a === void 0 ? void 0 : _a.length) === 0 && dispatch((0, general_1.setNoRapporti)({
          type: general_types_1.TypeNoRapporti.DEPOBOX
        }));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.getParametriTimeDepositBox = getParametriTimeDepositBox;
/* get parametri time deposit Box - FINE */
/* get time deposit list - INIZIO */
function getTimeDepositStart() {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_GET_TIME_DEPOSIT_START
  };
}
function getTimeDepositSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_GET_TIME_DEPOSIT_SUCCESS,
    payload
  };
}
/**
 *
 */
const getTimeDeposit = () => async (dispatch, getState, extraArguments) => {
  const state = getState().timeDeposit;
  const filtroTimeDepositDto = (0, timeDeposit_selectors_1.buildFilterTimeDeposit)(state);
  dispatch((0, general_1.setLoader)(true));
  dispatch(getTimeDepositStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/assegni_list.json')).then(parametri => {
      dispatch(getTimeDepositSuccess((0, api_rest_1.TimeDepositElencoDtoFromJSON)(parametri)));
    });
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      const request = {
        filtroTimeDepositDto
      };
      new api_rest_1.TimeDepositControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getTimeDeposit(request).then(response => {
        dispatch(getTimeDepositSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.getTimeDeposit = getTimeDeposit;
/* get time deposit list - FINE */
// GET TIME DEPOSIT SINGLE LIST START
function getTimeDepositSingleListStart() {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_GET_TIME_DEPOSIT_SINGLE_LIST_START
  };
}
;
function getTimeDepositSingleListSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_GET_TIME_DEPOSIT_SINGLE_LIST_SUCCESS,
    payload
  };
}
;
const getTimeDepositSingleList = () => async (dispatch, getState, extraArguments) => {
  const state = getState().timeDeposit;
  const filtroTimeDepositSingleListDto = (0, timeDeposit_selectors_1.buildFilterTimeDeposit)(state);
  dispatch((0, general_1.setLoader)(true));
  dispatch(getTimeDepositStart());
  if (extraArguments.mock) {
    /* import('../../mock/assegni_list.json').then(parametri => {
      dispatch(getTimeDepositSingleListSuccess(TimeDepositDtoFromJSON(Array<parametri>)));
    }); */
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      const request = {
        filtroTimeDepositDto: filtroTimeDepositSingleListDto
      };
      new api_rest_1.TimeDepositControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getTimeDepositSingleList(request).then(response => {
        dispatch(getTimeDepositSingleListSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.getTimeDepositSingleList = getTimeDepositSingleList;
// FILTRI
function setTimeDepositSetRapportoSelezionato(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_RAPPORTO_SELEZIONATO,
    payload
  };
}
exports.setTimeDepositSetRapportoSelezionato = setTimeDepositSetRapportoSelezionato;
function setTimeDepositSetStato(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_STATO,
    payload
  };
}
exports.setTimeDepositSetStato = setTimeDepositSetStato;
function setTimeDepositSetDataDecorrenzaDa(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_DATA_DECORRENZA_DA,
    payload
  };
}
exports.setTimeDepositSetDataDecorrenzaDa = setTimeDepositSetDataDecorrenzaDa;
function setTimeDepositSetDataDecorrenzaA(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_DATA_DECORRENZA_A,
    payload
  };
}
exports.setTimeDepositSetDataDecorrenzaA = setTimeDepositSetDataDecorrenzaA;
function setTimeDepositSetDataScadenzaDa(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_DATA_SCADENZA_DA,
    payload
  };
}
exports.setTimeDepositSetDataScadenzaDa = setTimeDepositSetDataScadenzaDa;
function setTimeDepositSetDataScadenzaA(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_DATA_SCADENZA_A,
    payload
  };
}
exports.setTimeDepositSetDataScadenzaA = setTimeDepositSetDataScadenzaA;
function resetFiltriTimeDeposit() {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_RESET_FILTRI
  };
}
exports.resetFiltriTimeDeposit = resetFiltriTimeDeposit;
function resetFiltriTimeDepositBox() {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_BOX_RESET_FILTRI
  };
}
exports.resetFiltriTimeDepositBox = resetFiltriTimeDepositBox;
function resetTimeDepositSelezionato() {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_RESET_TIME_DEPOSIT_SELEZIONATO
  };
}
exports.resetTimeDepositSelezionato = resetTimeDepositSelezionato;
function setTimeDepositSetPeriodo(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_PERIODO,
    payload
  };
}
exports.setTimeDepositSetPeriodo = setTimeDepositSetPeriodo;
/* Get listini time deposit - START */
function getListiniTimeDepositStart() {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_GET_LISTINI_START
  };
}
function getListiniTimeDepositSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_GET_LISTINI_SUCCESS,
    payload
  };
}
const getListiniTimeDeposit = () => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_1.setLoader)(true));
  dispatch(getListiniTimeDepositStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/assegni_list.json')).then(listini => {
      dispatch(getListiniTimeDepositSuccess([].map(() => (0, api_rest_1.ListiniTimeDepositDtoFromJSON)(listini))));
    });
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.TimeDepositControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getlistiniTimeDeposit().then(response => {
        dispatch(getListiniTimeDepositSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.getListiniTimeDeposit = getListiniTimeDeposit;
/* Get listini time deposit - END */
/* Info autenticazione time deposit - START */
function getInfoAutenticazioneStart() {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_GET_INFO_AUTENTICAZIONE_START
  };
}
function getInfoAutenticazioneSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_GET_INFO_AUTENTICAZIONE_SUCCESS,
    payload
  };
}
const getInfoAutenticazioneTimeDeposit = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_1.setLoader)(true));
  dispatch(getInfoAutenticazioneStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/assegni_list.json')).then(infoAutenticazione => {
      dispatch(getInfoAutenticazioneSuccess(infoAutenticazione));
    });
  } else {
    const requestParameters = {
      idDispositiva,
      chiamataUscente,
      eseguiInit,
      offline: !!offline
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.TimeDepositControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).informazioniAutenticazioneTimeDeposit(requestParameters).then(response => {
        dispatch(getInfoAutenticazioneSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.getInfoAutenticazioneTimeDeposit = getInfoAutenticazioneTimeDeposit;
/* Info autenticazione time deposit - END */
/* Simula apertura time deposit - START */
function simulaAperturaStart() {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_SIMULA_APERTURA_START
  };
}
function simulaAperturaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_SIMULA_APERTURA_SUCCESS,
    payload
  };
}
const simulaAperturaTimeDeposit = () => async (dispatch, getState, extraArguments) => {
  const state = getState().timeDeposit;
  dispatch((0, general_1.setLoader)(true));
  dispatch(simulaAperturaStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/assegni_list.json')).then(infoAutenticazione => {
      dispatch(simulaAperturaSuccess(infoAutenticazione));
    });
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      const requestParameters = {
        timeDepositDto: (0, timeDeposit_selectors_1.buildTimeDeposit)(state)
      };
      new api_rest_1.TimeDepositControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).simulaAperturaTimeDeposit(requestParameters).then(response => {
        dispatch(simulaAperturaSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.simulaAperturaTimeDeposit = simulaAperturaTimeDeposit;
/* Simula apertura time deposit - END */
/* Autorizza time deposit - START */
function autorizzaTimeDepositStart() {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_AUTORIZZA_TIME_DEPOSIT_START
  };
}
function autorizzaTimeDepositSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_AUTORIZZA_TIME_DEPOSIT_SUCCESS,
    payload
  };
}
const autorizzaTimeDeposit = (idDispositiva, credenzialeDispositivaDto) => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_1.setLoader)(true));
  dispatch(autorizzaTimeDepositStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/assegni_list.json')).then(infoAutenticazione => {
      dispatch(autorizzaTimeDepositSuccess(infoAutenticazione));
    });
  } else {
    const requestParameters = {
      credenzialeDispositivaDto,
      idDispositiva
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.TimeDepositControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).autorizzaTimeDeposit(requestParameters).then(response => {
        dispatch(autorizzaTimeDepositSuccess(response));
        extraArguments.sendEventToMatomo('TIME_DEPOSIT', "esito dispositiva TIME_DEPOSIT ".concat(response.stato), 'ESITO_DISPOSITIVA');
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error), false, extraArguments.sendEventToMatomo, 'TIME_DEPOSIT', 'ESITO_DISPOSITIVA'));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.autorizzaTimeDeposit = autorizzaTimeDeposit;
/* Autorizza time deposit - END */
// FORM
function setTimeDepositSetListinoSelezionato(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_LISTINO_SELEZIONATO,
    payload
  };
}
exports.setTimeDepositSetListinoSelezionato = setTimeDepositSetListinoSelezionato;
function setTimeDepositSetImportoDeposito(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_IMPORTO_DEPOSITO,
    payload
  };
}
exports.setTimeDepositSetImportoDeposito = setTimeDepositSetImportoDeposito;
function setTimeDepositResetForm() {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_RESET_FORM
  };
}
exports.setTimeDepositResetForm = setTimeDepositResetForm;
function setTimeDepositSetStep(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_STEP,
    payload
  };
}
exports.setTimeDepositSetStep = setTimeDepositSetStep;
function setTimeDepositResetStep() {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_RESET_STEP
  };
}
exports.setTimeDepositResetStep = setTimeDepositResetStep;
function setTimeDepositResetRiepilogo() {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_RESET_RIEPILOGO
  };
}
exports.setTimeDepositResetRiepilogo = setTimeDepositResetRiepilogo;
/* Info autenticazione revoca time deposit - START */
function getInfoAutenticazioneRevocaTimeDepositStart() {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_GET_INFO_AUTENTICAZIONE_REVOCA_START
  };
}
function getInfoAutenticazioneRevocaTimeDepositSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_GET_INFO_AUTENTICAZIONE_REVOCA_SUCCESS,
    payload
  };
}
const getInfoAutenticazioneRevocaTimeDeposit = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_1.setLoader)(true));
  dispatch(getInfoAutenticazioneRevocaTimeDepositStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/assegni_list.json')).then(infoAutenticazione => {
      dispatch(getInfoAutenticazioneRevocaTimeDepositSuccess(infoAutenticazione));
    });
  } else {
    const requestParameters = {
      idDispositiva,
      chiamataUscente,
      eseguiInit,
      offline: !!offline
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.TimeDepositControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).informazioniAutenticazioneRevocaTimeDeposit(requestParameters).then(response => {
        dispatch(getInfoAutenticazioneRevocaTimeDepositSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.getInfoAutenticazioneRevocaTimeDeposit = getInfoAutenticazioneRevocaTimeDeposit;
/* Info autenticazione revoca time deposit - END */
/* Simula revoca time deposit - START */
function simulaRevocaTimeDepositStart() {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_SIMULA_REVOCA_START
  };
}
function simulaRevocaTimeDepositSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_SIMULA_REVOCA_SUCCESS,
    payload
  };
}
const simulaRevocaTimeDeposit = () => async (dispatch, getState, extraArguments) => {
  var _a, _b, _c, _d;
  const state = getState().timeDeposit;
  dispatch((0, general_1.setLoader)(true));
  dispatch(simulaRevocaTimeDepositStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/assegni_list.json')).then(infoAutenticazione => {
      dispatch(simulaRevocaTimeDepositSuccess(infoAutenticazione));
    });
  } else {
    const requestParameters = {
      contoAddebito: (_b = (_a = state.timeDepositSelezionato) === null || _a === void 0 ? void 0 : _a.contoAddebito) !== null && _b !== void 0 ? _b : 0,
      numeroOperazione: (_d = (_c = state.timeDepositSelezionato) === null || _c === void 0 ? void 0 : _c.numeroOperazione) !== null && _d !== void 0 ? _d : 0
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.TimeDepositControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).simulaEstinzioneTimeDeposit(requestParameters).then(response => {
        dispatch(simulaRevocaTimeDepositSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.simulaRevocaTimeDeposit = simulaRevocaTimeDeposit;
/* Simula revoca time deposit - END */
/* Autorizza revoca time deposit - START */
function autorizzaRevocaTimeDepositStart() {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_AUTORIZZA_REVOCA_START
  };
}
function autorizzaRevocaTimeDepositSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_AUTORIZZA_REVOCA_SUCCESS,
    payload
  };
}
const autorizzaRevocaTimeDeposit = (idDispositiva, credenziale) => async (dispatch, getState, extraArguments) => {
  const state = getState().timeDeposit;
  dispatch((0, general_1.setLoader)(true));
  dispatch(autorizzaRevocaTimeDepositStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/assegni_list.json')).then(infoAutenticazione => {
      dispatch(autorizzaRevocaTimeDepositSuccess(infoAutenticazione));
    });
  } else {
    const requestParameters = {
      credenzialeDispositivaDto: credenziale,
      idDispositiva
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.TimeDepositControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).autorizzaRevocaTimeDeposit(requestParameters).then(response => {
        dispatch(autorizzaRevocaTimeDepositSuccess(response));
        extraArguments.sendEventToMatomo('TIME_DEPOSIT_REVOCA', "esito revoca dispositiva TIME_DEPOSIT_REVOCA ".concat(response.stato), 'REVOCA_DISPOSITIVA');
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error), false, extraArguments.sendEventToMatomo, 'TIME_DEPOSIT_REVOCA', 'REVOCA_DISPOSITIVA'));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.autorizzaRevocaTimeDeposit = autorizzaRevocaTimeDeposit;
/* Simula revoca time deposit - END */
/* get dettaglio time deposit - START */
function getDettaglioTimeDepositStart() {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_GET_DETTAGLIO_START
  };
}
function getDettaglioTimeDepositSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_GET_DETTAGLIO_SUCCESS,
    payload
  };
}
const getDettaglioTimeDeposit = (numeroOperazione, exportPdf) => async (dispatch, getState, extraArguments) => {
  var _a, _b, _c;
  const state = getState().timeDeposit;
  dispatch((0, general_1.setLoader)(true));
  dispatch(getDettaglioTimeDepositStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/assegni_list.json')).then(infoAutenticazione => {
      dispatch(getDettaglioTimeDepositSuccess(infoAutenticazione));
    });
  } else {
    const requestParameters = {
      codiceRapporto: (_c = (_b = (_a = state.filter.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : 0,
      numeroOperazione
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.TimeDepositControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getDettaglioTimeDeposit(requestParameters).then(response => {
        dispatch(getDettaglioTimeDepositSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.getDettaglioTimeDeposit = getDettaglioTimeDeposit;
/* Revoca time deposit - END */
// EXPORT
/* EXPORT ELENCO - START */
function esportaElencoTimeDepositPDFStart() {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_ESPORTA_PDF_START
  };
}
function esportaElencoTimeDepositPDFSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_ESPORTA_PDF_SUCCESS,
    payload
  };
}
const esportaElencoTimeDepositPDF = (share, title, message, isDepoBox) => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_1.setLoader)(true));
  dispatch(esportaElencoTimeDepositPDFStart());
  const state = getState().timeDeposit;
  if (extraArguments.mock) {
    /* TODO: vedere nella parte mockata cosa restituire
    import('../../mock/lista_movimenti.json').then(parametri => {
      dispatch(esportaPagaimentiPDFSuccess(ListaMovimentiDtoFromJSON(parametri)));
    });
    */
  } else {
    const exportElencoRequest = {
      type: api_rest_1.StampaElencoTimeDepositTypeEnum.pdf,
      filtroTimeDepositDto: {
        ...(0, timeDeposit_selectors_1.buildFilterTimeDeposit)(state),
        isDepoBox
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.TimeDepositControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).stampaElencoTimeDeposit(exportElencoRequest).then(response => {
        if (extraArguments.platform === 'desktop') {
          (0, helpers_1.hadlePdfResponse)(response, url => dispatch((0, general_1.popUpBlockedPdf)(url)));
        }
        if (extraArguments.platform === 'mobile') {
          (0, helpers_1.download_sharePDF)(extraArguments, 'ElencoTimeDeposit.pdf', response, share, title, message);
        }
        dispatch(esportaElencoTimeDepositPDFSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.esportaElencoTimeDepositPDF = esportaElencoTimeDepositPDF;
function esportaElencoTimeDepositExcelStart() {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_ESPORTA_EXCEL_START
  };
}
function esportaElencoTimeDepositExcelSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_ESPORTA_EXCEL_SUCCESS,
    payload
  };
}
const esportaElencoTimeDepositExcel = (share, title, message, isDepoBox) => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_1.setLoader)(true));
  dispatch(esportaElencoTimeDepositExcelStart());
  const state = getState().timeDeposit;
  if (extraArguments.mock) {
    /* TODO: vedere nella parte mockata cosa restituire
    import('../../mock/lista_movimenti.json').then(parametri => {
      dispatch(esportaPagaimentiPDFSuccess(ListaMovimentiDtoFromJSON(parametri)));
    });
    */
  } else {
    const exportElencoRequest = {
      type: api_rest_1.StampaElencoTimeDepositTypeEnum.excel,
      filtroTimeDepositDto: {
        ...(0, timeDeposit_selectors_1.buildFilterTimeDeposit)(state),
        isDepoBox
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.TimeDepositControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/vnd.ms-excel')).stampaElencoTimeDeposit(exportElencoRequest).then(response => {
        if (extraArguments.platform === 'desktop') {
          (0, helpers_1.hadleExcelResponse)(response, 'elenco_timeDeposit.xlsx');
        }
        if (extraArguments.platform === 'mobile') {
          (0, helpers_1.download_shareXLS)(extraArguments, 'ElencoTimeDeposit.xls', response, share);
        }
        dispatch(esportaElencoTimeDepositExcelSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.esportaElencoTimeDepositExcel = esportaElencoTimeDepositExcel;
/* EXPORT ELENCO - END */
/* EXPORT DETTAGLIO - START */
function esportaDettaglioTimeDepositPDFStart() {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_ESPORTA_DETTAGLIO_PDF_START
  };
}
function esportaDettaglioTimeDepositPDFSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_ESPORTA_DETTAGLIO_PDF_SUCCESS,
    payload
  };
}
const esportaDettaglioTimeDepositPDF = (share, title, message, isDepoBox, timeDeposit) => async (dispatch, getState, extraArguments) => {
  var _a;
  dispatch((0, general_1.setLoader)(true));
  dispatch(esportaDettaglioTimeDepositPDFStart());
  const state = getState().timeDeposit;
  if (extraArguments.mock) {} else {
    const exportElencoRequest = {
      filtroDettaglioTimeDepositDto: {
        isDepoBox,
        timeDeposit: ((timeDeposit === null || timeDeposit === void 0 ? void 0 : timeDeposit.numeroOperazione) ? timeDeposit : undefined) || ((_a = state.esito) === null || _a === void 0 ? void 0 : _a.disposizione) || (0, timeDeposit_selectors_1.timeDepositSelezionato)(state) || {}
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.TimeDepositControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).dettaglioTimeDepositPdf(exportElencoRequest).then(response => {
        if (extraArguments.platform === 'desktop') {
          (0, helpers_1.hadlePdfResponse)(response, url => dispatch((0, general_1.popUpBlockedPdf)(url)));
        }
        if (extraArguments.platform === 'mobile') {
          (0, helpers_1.download_sharePDF)(extraArguments, 'DettaglioTimeDeposit.pdf', response, share, title, message);
        }
        dispatch(esportaDettaglioTimeDepositPDFSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.esportaDettaglioTimeDepositPDF = esportaDettaglioTimeDepositPDF;
/* EXPORT DETTAGLIO - END */
// RESET LISTINI
function resetListiniTimeDeposit() {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_RESET_LISTINI
  };
}
exports.resetListiniTimeDeposit = resetListiniTimeDeposit;
// Form revoca
function setTimeDepositSetStepRevoca(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_FORM_REVOCA_STEP,
    payload
  };
}
exports.setTimeDepositSetStepRevoca = setTimeDepositSetStepRevoca;
function resetRevocaTimeDeposit() {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_RESET_REVOCA
  };
}
exports.resetRevocaTimeDeposit = resetRevocaTimeDeposit;
/* Get piano cedole - START */
function getPianoCedoleStart() {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_GET_PIANO_CEDOLE_START
  };
}
function getPianoCedoleSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_GET_PIANO_CEDOLE_SUCCESS,
    payload
  };
}
const getPianoCedole = () => async (dispatch, getState, extraArguments) => {
  var _a, _b, _c, _d, _e;
  const state = getState().timeDeposit;
  dispatch((0, general_1.setLoader)(true));
  dispatch(getPianoCedoleStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/assegni_list.json')).then(listini => {
      dispatch(getPianoCedoleSuccess([].map(() => (0, api_rest_1.PianoCedoleDtoFromJSON)(listini))));
    });
  } else {
    const request = {
      codiceRapporto: (_c = (_b = (_a = state.filter.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : 0,
      numeroOperazione: (_e = (_d = state.timeDepositSelezionato) === null || _d === void 0 ? void 0 : _d.numeroOperazione) !== null && _e !== void 0 ? _e : 0
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.TimeDepositControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).pianoCedoleTimeDeposit(request).then(response => {
        dispatch(getPianoCedoleSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.getPianoCedole = getPianoCedole;
/* Get listini time deposit - END */
function resetTimeDepositCedole() {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_RESET_CEDOLE
  };
}
exports.resetTimeDepositCedole = resetTimeDepositCedole;
function resetTimeDeposit() {
  return {
    type: __1.EReduxActionTypes.TIME_DEPOSIT_RESET
  };
}
exports.resetTimeDeposit = resetTimeDeposit;
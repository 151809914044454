"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setTipoDivisaSelezionata = exports.setTipoElencoCartaConto = exports.resetMovimentiHomepage = exports.rimuoviFiltriCartaConto = exports.rimuoviFiltriSaldoMovimenti = exports.resetFiltriMovimenti = exports.resetSaldoEMovimenti = exports.resetMovimenti = exports.getSaldoComplessivo = exports.getParametriCartaConto = exports.setMovimentiSetAccordionFiltriOpened = exports.setMovimentiEntrataUscita = exports.setMovimentiSetEntrateUscite = exports.setMovimentiSetOrdinamento = exports.setMovimentiSetUscite = exports.setMovimentiSetEntrate = exports.setMovimentiSetPeriodo = exports.setMovimentiSetImporto = exports.setMovimentiSetCategorie = exports.setMovimentiSetDataA = exports.setMovimentiSetDataDa = exports.setMovimentiSetDescrizione = exports.setMovimentiSetOffset = exports.setMovimentiSetRapportoSelezionatoContoDeposito = exports.setMovimentiSetRapportoSelezionato = exports.resetFiltri = exports.esportaDettaglioMovimentoPDF = exports.esportaMovimentiEXCEL = exports.esportaMovimentiPDF = exports.getMovimenti = exports.getMovimentiContoDepositoFiltrati = exports.getMovimentiCartaContoFiltrati = exports.getMovimentiFiltrati = exports.getMovimentiHomepage = exports.getParametriMovimentiContoDeposito = exports.movimentiSwitchToTempParametri = exports.getParametriMovimenti = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const general_types_1 = require("../general/general.types");
const helpers_1 = require("../helpers");
const movimenti_selectors_1 = require("./movimenti.selectors");
/* get parametri movimenti - START */
function getParametriMovimentiStart() {
  return {
    type: __1.EReduxActionTypes.MOVIMENTI_GET_PARAMETRI_START
  };
}
function getParametriMovimentiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.MOVIMENTI_GET_PARAMETRI_SUCCESS,
    payload
  };
}
function generalError(value) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value));
  };
}
function getParametriMovimentiContoDepositoStart() {
  return {
    type: __1.EReduxActionTypes.MOVIMENTI_GET_PARAMETRI_CONTO_DEPOSITO_START
  };
}
function getParametriMovimentiContoDepositoSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.MOVIMENTI_GET_PARAMETRI_CONTO_DEPOSITO_SUCCESS,
    payload
  };
}
const getParametriMovimenti = function () {
  let psd2in = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  let loaderEnabled = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return async (dispatch, _, extraArguments) => {
    loaderEnabled && dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriMovimentiStart());
    if (extraArguments.mock) {
      Promise.resolve().then(() => require('../../mock/parametri_movimenti.json')).then(parametri => {
        dispatch(getParametriMovimentiSuccess((0, api_rest_1.ParametriMovimentiDtoFromJSON)(parametri)));
        loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
      });
    } else {
      extraArguments.getItem('jwt').then(jwt => {
        const request = {
          psd2in
        };
        new api_rest_1.MovimentiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriMovimenti(request).then(response => {
          dispatch(getParametriMovimentiSuccess(response));
        }).catch(async error => {
          dispatch(generalError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
          loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
        });
      });
    }
  };
};
exports.getParametriMovimenti = getParametriMovimenti;
function movimentiSwitchToTempParametri() {
  return {
    type: __1.EReduxActionTypes.MOVIMENTI_SWITCH_TO_TEMP_PARAMETRI
  };
}
exports.movimentiSwitchToTempParametri = movimentiSwitchToTempParametri;
const getParametriMovimentiContoDeposito = function () {
  let loaderEnabled = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  return async (dispatch, _, extraArguments) => {
    loaderEnabled && dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriMovimentiContoDepositoStart());
    if (extraArguments.mock) {
      Promise.resolve().then(() => require('../../mock/parametri_movimenti.json')).then(parametri => {
        dispatch(getParametriMovimentiContoDepositoSuccess((0, api_rest_1.ParametriMovimentiDtoFromJSON)(parametri)));
        loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
      });
    } else {
      extraArguments.getItem('jwt').then(jwt => {
        new api_rest_1.MovimentiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriMovimentiContoDeposito().then(response => {
          var _a;
          dispatch(getParametriMovimentiContoDepositoSuccess(response));
          ((_a = response.rapporti) === null || _a === void 0 ? void 0 : _a.length) === 0 && dispatch((0, general_actions_1.setNoRapporti)({
            type: general_types_1.TypeNoRapporti.GENERIC
          }));
        }).catch(async error => {
          dispatch(generalError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
          loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
        });
      });
    }
  };
};
exports.getParametriMovimentiContoDeposito = getParametriMovimentiContoDeposito;
/* get parametri movimenti - FINE */
/* get movimenti homepage - START */
function getMovimentiHomepageStart() {
  return {
    type: __1.EReduxActionTypes.MOVIMENTI_GET_MOVIMENTI_HOMEPAGE_START
  };
}
function getMovimentiHomepageSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.MOVIMENTI_GET_MOVIMENTI_HOMEPAGE_SUCCESS,
    payload
  };
}
const getMovimentiHomepage = function () {
  let psd2in = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  let loaderEnabled = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return async (dispatch, _, extraArguments) => {
    dispatch(getMovimentiHomepageStart());
    loaderEnabled && dispatch((0, general_actions_1.setLoader)(true));
    if (extraArguments.mock) {
      Promise.resolve().then(() => require('../../mock/lista_movimenti-homepage.json')).then(parametri => {
        dispatch(getMovimentiHomepageSuccess((0, api_rest_1.ListaMovimentiDtoFromJSON)(parametri)));
        loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
      });
    } else {
      extraArguments.getItem('jwt').then(jwt => {
        const request = {
          psd2in
        };
        new api_rest_1.MovimentiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getListaMovimentiHome(request).then(response => {
          dispatch(getMovimentiHomepageSuccess(response));
        }).catch(async error => {
          dispatch(generalError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
          loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
        });
      });
    }
  };
};
exports.getMovimentiHomepage = getMovimentiHomepage;
/* get movimenti homepage - FINE */
/* get movimenti di saldo e movimenti - START */
function getMovimentiStart() {
  return {
    type: __1.EReduxActionTypes.MOVIMENTI_GET_MOVIMENTI_START
  };
}
function getMovimentiSuccess(payload, offset) {
  return {
    type: __1.EReduxActionTypes.MOVIMENTI_GET_MOVIMENTI_SUCCESS,
    payload,
    offset
  };
}
const getMovimentiFiltrati = () => async (dispatch, getState, extraArguments) => {
  const datiForm = (0, movimenti_selectors_1.formElencoMovimentiAsFiltro)(getState().movimenti);
  datiForm && (0, exports.getMovimenti)(datiForm, false)(dispatch, getState, extraArguments);
};
exports.getMovimentiFiltrati = getMovimentiFiltrati;
const getMovimentiCartaContoFiltrati = () => async (dispatch, getState, extraArguments) => {
  const datiForm = (0, movimenti_selectors_1.formElencoMovimentiCartaContoAsFiltro)(getState().movimenti);
  datiForm && (0, exports.getMovimenti)(datiForm, false)(dispatch, getState, extraArguments);
};
exports.getMovimentiCartaContoFiltrati = getMovimentiCartaContoFiltrati;
const getMovimentiContoDepositoFiltrati = () => async (dispatch, getState, extraArguments) => {
  const datiForm = (0, movimenti_selectors_1.formElencoMovimentiContoDepositoAsFiltro)(getState().movimenti);
  datiForm && (0, exports.getMovimenti)(datiForm, false)(dispatch, getState, extraArguments);
};
exports.getMovimentiContoDepositoFiltrati = getMovimentiContoDepositoFiltrati;
const getMovimenti = (datiForm, homepage, forceLoaderEnabled) => async (dispatch, _, extraArguments) => {
  if (!homepage && (!datiForm.offset || datiForm.offset === 0) || forceLoaderEnabled) {
    dispatch((0, general_actions_1.setLoader)(true));
  }
  if (datiForm.offset && datiForm.offset > 0) {
    dispatch(setMovimentiListLoader(true));
  }
  dispatch(getMovimentiStart());
  if (!homepage && (!datiForm.offset || datiForm.offset === 0)) {
    dispatch(resetMovimenti());
  }
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/lista_movimenti.json')).then(response => {
      dispatch(homepage ? getMovimentiHomepageSuccess((0, api_rest_1.ListaMovimentiDtoFromJSON)(response)) : getMovimentiSuccess((0, api_rest_1.ListaMovimentiDtoFromJSON)(response), datiForm.offset));
      if (!homepage && (!datiForm.offset || datiForm.offset === 0) || forceLoaderEnabled) {
        dispatch((0, general_actions_1.setLoader)(false));
      }
      if (datiForm.offset && datiForm.offset > 0) {
        dispatch(setMovimentiListLoader(false));
      }
    });
  } else {
    const getMovimentiRequest = {
      criteriRicercaMovimentiRequestIn: datiForm
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.MovimentiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getListaMovimenti(getMovimentiRequest).then(response => {
        dispatch(homepage ? getMovimentiHomepageSuccess(response) : getMovimentiSuccess(response, datiForm.offset));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        if (!homepage && (!datiForm.offset || datiForm.offset === 0) || forceLoaderEnabled) {
          dispatch((0, general_actions_1.setLoader)(false));
        }
        if (datiForm.offset && datiForm.offset > 0) {
          dispatch(setMovimentiListLoader(false));
        }
      });
    });
  }
};
exports.getMovimenti = getMovimenti;
/* get movimenti di saldo e movimenti - FINE */
/* esporta PDF lista movimenti - START */
function esportaMovimentiPDFStart() {
  return {
    type: __1.EReduxActionTypes.MOVIMENTI_ESPORTA_PDF_START
  };
}
function esportaMovimentiPDFSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.MOVIMENTI_ESPORTA_PDF_SUCCESS,
    payload
  };
}
const esportaMovimentiPDF = (datiForm, share, title, message) => async (dispatch, getState, extraArguments) => {
  var _a, _b;
  dispatch(esportaMovimentiPDFStart());
  dispatch((0, general_actions_1.setLoader)(true));
  if (extraArguments.mock) {
    /* TODO: vedere nella parte mockata cosa restituire
      import('../../mock/lista_movimenti.json').then(parametri => {
        dispatch(esportaMovimentiPDFSuccess(ListaMovimentiDtoFromJSON(parametri)));
      });
      */
  } else {
    const requestIn = datiForm || (0, movimenti_selectors_1.formElencoMovimentiAsFiltro)(getState().movimenti);
    const isDeposito = (0, movimenti_selectors_1.formSaldoEMovimenti)(getState().movimenti).isDeposito;
    const isCarta = (0, movimenti_selectors_1.formSaldoEMovimenti)(getState().movimenti).isCarta;
    const rapportoSelezionato = isDeposito ? getState().movimenti.form.rapportoSelezionatoContoDeposito : getState().movimenti.form.rapportoSelezionato;
    if (requestIn) {
      const esportaMovimentiPDFRequest = {
        type: api_rest_1.ElencoMovimentiPdfTypeEnum.pdf,
        criteriRicercaMovimentiRequestIn: {
          ...requestIn,
          contoDeposito: isDeposito,
          cartaconto: isCarta,
          saldoContabile: (_a = rapportoSelezionato.saldo) === null || _a === void 0 ? void 0 : _a.saldoContabile,
          saldoDisponibile: (_b = rapportoSelezionato.saldo) === null || _b === void 0 ? void 0 : _b.saldoDisponibile
        }
      };
      extraArguments.getItem('jwt').then(jwt => {
        new api_rest_1.MovimentiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).elencoMovimentiPdf(esportaMovimentiPDFRequest).then(response => {
          if (extraArguments.platform === 'desktop') {
            (0, helpers_1.hadlePdfResponse)(response, url => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
          }
          if (extraArguments.platform === 'mobile') {
            (0, helpers_1.download_sharePDF)(extraArguments, 'movimenti.pdf', response, share, title, message);
          }
          dispatch(esportaMovimentiPDFSuccess(response));
        }).catch(async error => {
          dispatch(generalError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
          dispatch((0, general_actions_1.setLoader)(false));
        });
      });
    }
  }
};
exports.esportaMovimentiPDF = esportaMovimentiPDF;
/* esporta PDF lista movimenti - FINE */
/* esporta EXCEL lista movimenti - START */
function esportaMovimentiEXCELStart() {
  return {
    type: __1.EReduxActionTypes.MOVIMENTI_ESPORTA_EXCEL_START
  };
}
function esportaMovimentiEXCELSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.MOVIMENTI_ESPORTA_EXCEL_SUCCESS,
    payload
  };
}
const esportaMovimentiEXCEL = (datiForm, share) => async (dispatch, getState, extraArguments) => {
  var _a, _b;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(esportaMovimentiEXCELStart());
  if (extraArguments.mock) {
    /* TODO: vedere nella parte mockata cosa restituire
      import('../../mock/lista_movimenti.json').then(parametri => {
        dispatch(esportaMovimentiEXCELSuccess(ListaMovimentiDtoFromJSON(parametri)));
      });
      */
  } else {
    const requestIn = datiForm || (0, movimenti_selectors_1.formElencoMovimentiAsFiltro)(getState().movimenti);
    const isDeposito = (0, movimenti_selectors_1.formSaldoEMovimenti)(getState().movimenti).isDeposito;
    const rapportoSelezionato = isDeposito ? getState().movimenti.form.rapportoSelezionatoContoDeposito : getState().movimenti.form.rapportoSelezionato;
    if (requestIn) {
      const esportaMovimentiEXCELRequest = {
        type: api_rest_1.ElencoMovimentiPdfTypeEnum.excel,
        criteriRicercaMovimentiRequestIn: {
          ...requestIn,
          contoDeposito: isDeposito,
          saldoContabile: (_a = rapportoSelezionato.saldo) === null || _a === void 0 ? void 0 : _a.saldoContabile,
          saldoDisponibile: (_b = rapportoSelezionato.saldo) === null || _b === void 0 ? void 0 : _b.saldoDisponibile
        }
      };
      extraArguments.getItem('jwt').then(jwt => {
        new api_rest_1.MovimentiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/vnd.ms-excel')).elencoMovimentiPdf(esportaMovimentiEXCELRequest).then(response => {
          if (extraArguments.platform === 'desktop') {
            (0, helpers_1.hadleExcelResponse)(response, 'movimenti.xlsx');
          }
          if (extraArguments.platform === 'mobile') {
            (0, helpers_1.download_shareXLS)(extraArguments, 'ElencoMovimenti.xls', response, share);
          }
          dispatch(esportaMovimentiEXCELSuccess(response));
        }).catch(async error => {
          dispatch(generalError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
          dispatch((0, general_actions_1.setLoader)(false));
        });
      });
    }
  }
};
exports.esportaMovimentiEXCEL = esportaMovimentiEXCEL;
/* esporta EXCEL lista movimenti - FINE */
/* esporta PDF dettaglio movimento - START */
function esportaDettaglioMovimentoPDFStart() {
  return {
    type: __1.EReduxActionTypes.MOVIMENTI_ESPORTA_DETTAGLIO_PDF_START
  };
}
function esportaDettaglioMovimentoPDFSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.MOVIMENTI_ESPORTA_DETTAGLIO_PDF_SUCCESS,
    payload
  };
}
//passare anche un obj con codiceRapporto , intestazione , stima 
const esportaDettaglioMovimentoPDF = (movimento, share, title, message, criteriPsd2) => async (dispatch, getState, extraArguments) => {
  var _a;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch((0, general_actions_1.setWaitingSection)(true, "PDF".concat(movimento.numeroOperazione)));
  dispatch(esportaDettaglioMovimentoPDFStart());
  if (extraArguments.mock) {
    /* TODO: vedere nella parte mockata cosa restituire
    import('../../mock/lista_movimenti.json').then(parametri => {
      dispatch(esportaDettaglioMovimentoPDFSuccess(ListaMovimentiDtoFromJSON(parametri)));
    });
    */
  } else {
    const isCarta = (0, movimenti_selectors_1.formSaldoEMovimenti)(getState().movimenti).isCarta;
    const isDeposito = (0, movimenti_selectors_1.formSaldoEMovimenti)(getState().movimenti).isDeposito;
    const esportaDettaglioMovimentoPDFRequest = {
      exportMovimentiInDto: {
        movimento: movimento,
        cartaconto: isCarta,
        contoDeposito: isDeposito,
        criteriPsd2: ((_a = movimento.psd2) === null || _a === void 0 ? void 0 : _a.transactionId) ? criteriPsd2 : undefined
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.MovimentiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).dettaglioMovimentiPdf(esportaDettaglioMovimentoPDFRequest).then(async response => {
        if (extraArguments.platform === 'desktop') {
          (0, helpers_1.hadlePdfResponse)(response, url => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
        }
        if (extraArguments.platform === 'mobile') {
          (0, helpers_1.download_sharePDF)(extraArguments, "movimento-".concat(movimento.numeroOperazione, ".pdf"), response, share, title, message);
        }
        dispatch(esportaDettaglioMovimentoPDFSuccess(response));
      }).catch(async error => {
        try {
          dispatch(generalError(await (0, helpers_1.formatError)(error)));
        } catch (e) {
          console.log(e);
        }
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
        dispatch((0, general_actions_1.setWaitingSection)(false, "PDF".concat(movimento.numeroOperazione)));
      });
    });
  }
};
exports.esportaDettaglioMovimentoPDF = esportaDettaglioMovimentoPDF;
/* esporta PDF dettaglio movimento - FINE */
function resetFiltri() {
  return {
    type: __1.EReduxActionTypes.SALDO_E_MOVIMENTI_RESET_FILTRO
  };
}
exports.resetFiltri = resetFiltri;
function setMovimentiSetRapportoSelezionato(rapportoSelezionato) {
  return {
    type: __1.EReduxActionTypes.SALDO_E_MOVIMENTI_SET_RAPPORTO_SELEZIONATO,
    payload: rapportoSelezionato
  };
}
exports.setMovimentiSetRapportoSelezionato = setMovimentiSetRapportoSelezionato;
function setMovimentiSetRapportoSelezionatoContoDeposito(rapportoSelezionato) {
  return {
    type: __1.EReduxActionTypes.SALDO_E_MOVIMENTI_SET_RAPPORTO_SELEZIONATO_CONTO_DEPOSITO,
    payload: rapportoSelezionato
  };
}
exports.setMovimentiSetRapportoSelezionatoContoDeposito = setMovimentiSetRapportoSelezionatoContoDeposito;
function setMovimentiSetOffset(offset) {
  return {
    type: __1.EReduxActionTypes.SALDO_E_MOVIMENTI_OFFSET,
    payload: offset
  };
}
exports.setMovimentiSetOffset = setMovimentiSetOffset;
function setMovimentiListLoader(show) {
  return {
    type: __1.EReduxActionTypes.SALDO_E_MOVIMENTI_LIST_LOADER,
    payload: show
  };
}
function setMovimentiSetDescrizione(txt) {
  return {
    type: __1.EReduxActionTypes.SALDO_E_MOVIMENTI_DESCRIZIONE,
    payload: txt
  };
}
exports.setMovimentiSetDescrizione = setMovimentiSetDescrizione;
function setMovimentiSetDataDa(txt) {
  return {
    type: __1.EReduxActionTypes.SALDO_E_MOVIMENTI_DATA_DA,
    payload: txt
  };
}
exports.setMovimentiSetDataDa = setMovimentiSetDataDa;
function setMovimentiSetDataA(txt) {
  return {
    type: __1.EReduxActionTypes.SALDO_E_MOVIMENTI_DATA_A,
    payload: txt
  };
}
exports.setMovimentiSetDataA = setMovimentiSetDataA;
function setMovimentiSetCategorie(cats) {
  return {
    type: __1.EReduxActionTypes.SALDO_E_MOVIMENTI_CATEGORIE,
    payload: cats
  };
}
exports.setMovimentiSetCategorie = setMovimentiSetCategorie;
function setMovimentiSetImporto(num) {
  return {
    type: __1.EReduxActionTypes.SALDO_E_MOVIMENTI_IMPORTO,
    payload: num
  };
}
exports.setMovimentiSetImporto = setMovimentiSetImporto;
function setMovimentiSetPeriodo(txt) {
  return {
    type: __1.EReduxActionTypes.SALDO_E_MOVIMENTI_PERIODO,
    payload: txt
  };
}
exports.setMovimentiSetPeriodo = setMovimentiSetPeriodo;
function setMovimentiSetEntrate(val) {
  return {
    type: __1.EReduxActionTypes.SALDO_E_MOVIMENTI_ENTRATE,
    payload: val
  };
}
exports.setMovimentiSetEntrate = setMovimentiSetEntrate;
function setMovimentiSetUscite(val) {
  return {
    type: __1.EReduxActionTypes.SALDO_E_MOVIMENTI_USCITE,
    payload: val
  };
}
exports.setMovimentiSetUscite = setMovimentiSetUscite;
function setMovimentiSetOrdinamento(val) {
  return {
    type: __1.EReduxActionTypes.SALDO_E_MOVIMENTI_ORDINAMENTO,
    payload: val
  };
}
exports.setMovimentiSetOrdinamento = setMovimentiSetOrdinamento;
function setMovimentiSetEntrateUscite(val) {
  return {
    type: __1.EReduxActionTypes.SALDO_E_MOVIMENTI_ENTRATE_USCITE,
    payload: val
  };
}
exports.setMovimentiSetEntrateUscite = setMovimentiSetEntrateUscite;
function setMovimentiEntrataUscita(val) {
  return {
    type: __1.EReduxActionTypes.SALDO_E_MOVIMENTI_ENTRATA_USCITA,
    payload: val
  };
}
exports.setMovimentiEntrataUscita = setMovimentiEntrataUscita;
function setMovimentiSetAccordionFiltriOpened(opened) {
  return {
    type: __1.EReduxActionTypes.SALDO_E_MOVIMENTI_SET_ACCORDION_FILTRI_OPENED,
    payload: opened
  };
}
exports.setMovimentiSetAccordionFiltriOpened = setMovimentiSetAccordionFiltriOpened;
/* get parametri carta conto - START */
function getParametriCartaContoStart() {
  return {
    type: __1.EReduxActionTypes.MOVIMENTI_GET_PARAMETRI_CARTA_CONTO_START
  };
}
function getParametriCartaContoSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.MOVIMENTI_GET_PARAMETRI_CARTA_CONTO_SUCCESS,
    payload
  };
}
const getParametriCartaConto = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getParametriCartaContoStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/parametri_movimenti.json')).then(parametri => {
      dispatch(getParametriCartaContoSuccess((0, api_rest_1.ParametriMovimentiDtoFromJSON)(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.MovimentiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriMovimentiCartaConto().then(response => {
        dispatch(getParametriCartaContoSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getParametriCartaConto = getParametriCartaConto;
/* get parametri carta conto - FINE */
function getSaldoComplessivoStart() {
  return {
    type: __1.EReduxActionTypes.RAPPORTI_GET_SALDO_COMPLESSIVO_START
  };
}
function getSaldoComplessivoSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.RAPPORTI_GET_SALDO_COMPLESSIVO_SUCCESS,
    payload
  };
}
const getSaldoComplessivo = function () {
  let psd2in = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  let showLoader = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return async (dispatch, _, extraArguments) => {
    showLoader && dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getSaldoComplessivoStart());
    if (extraArguments.mock) {
      Promise.resolve().then(() => require('../../mock/saldo_complessivo.json')).then(parametri => {
        dispatch(getSaldoComplessivoSuccess((0, api_rest_1.SaldoDtoFromJSON)(parametri)));
      });
      showLoader && dispatch((0, general_actions_1.setLoader)(false));
    } else {
      extraArguments.getItem('jwt').then(jwt => {
        const request = {
          psd2in
        };
        new api_rest_1.RapportiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).saldi(request).then(response => {
          dispatch(getSaldoComplessivoSuccess(response));
        }).catch(async error => {
          dispatch(generalError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
          showLoader && dispatch((0, general_actions_1.setLoader)(false));
        });
      });
    }
  };
};
exports.getSaldoComplessivo = getSaldoComplessivo;
function resetMovimenti() {
  return {
    type: __1.EReduxActionTypes.MOVIMENTI_RESET_MOVIMENTI
  };
}
exports.resetMovimenti = resetMovimenti;
function resetSaldoEMovimenti() {
  return {
    type: __1.EReduxActionTypes.MOVIMENTI_RESET_SALDO_E_MOVIMENTI
  };
}
exports.resetSaldoEMovimenti = resetSaldoEMovimenti;
function resetFiltriMovimenti() {
  return {
    type: __1.EReduxActionTypes.SALDO_E_MOVIMENTI_RESET_FILTRI_MOVIMENTI
  };
}
exports.resetFiltriMovimenti = resetFiltriMovimenti;
function rimuoviFiltriSaldoMovimenti() {
  return {
    type: __1.EReduxActionTypes.SALDO_MOVIMENTI_RIMUOVI_FILTRI
  };
}
exports.rimuoviFiltriSaldoMovimenti = rimuoviFiltriSaldoMovimenti;
function rimuoviFiltriCartaConto() {
  return {
    type: __1.EReduxActionTypes.CARTA_CONTO_RIMUOVI_FILTRI
  };
}
exports.rimuoviFiltriCartaConto = rimuoviFiltriCartaConto;
function resetMovimentiHomepage() {
  return {
    type: __1.EReduxActionTypes.MOVIMENTI_RESET_MOVIMENTI_HOMEPAGE
  };
}
exports.resetMovimentiHomepage = resetMovimentiHomepage;
function setTipoElencoCartaConto() {
  return {
    type: __1.EReduxActionTypes.MOVIMENTI_SET_TIPO_ELENCO_CARTA_CONTO
  };
}
exports.setTipoElencoCartaConto = setTipoElencoCartaConto;
function setTipoDivisaSelezionata(payload) {
  return {
    type: __1.EReduxActionTypes.MOVIMENTI_SET_TIPO_DIVISA_SELEZIONATA,
    payload
  };
}
exports.setTipoDivisaSelezionata = setTipoDivisaSelezionata;
"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SelfResetControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class SelfResetControllerApi extends runtime.BaseAPI {
  /**
   * Recupera lo stato di un processo di self reset
   */
  getSelfResetStatusRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.oauthSrToken === null || requestParameters.oauthSrToken === undefined) {
        throw new runtime.RequiredError('oauthSrToken', 'Required parameter requestParameters.oauthSrToken was null or undefined when calling getSelfResetStatus.');
      }
      if (requestParameters.selfResetStatusRequestDto === null || requestParameters.selfResetStatusRequestDto === undefined) {
        throw new runtime.RequiredError('selfResetStatusRequestDto', 'Required parameter requestParameters.selfResetStatusRequestDto was null or undefined when calling getSelfResetStatus.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      if (requestParameters.oauthSrToken !== undefined && requestParameters.oauthSrToken !== null) {
        headerParameters['oauth-sr-token'] = String(requestParameters.oauthSrToken);
      }
      const response = yield this.request({
        path: "/public/selfreset/status",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.SelfResetStatusRequestDtoToJSON(requestParameters.selfResetStatusRequestDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.SelfResetStatusResponseDtoFromJSON(jsonValue));
    });
  }
  /**
   * Recupera lo stato di un processo di self reset
   */
  getSelfResetStatus(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getSelfResetStatusRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Registra l\'inizializzazione di un processo di self reset
   */
  startSelfResetRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.oauthSrToken === null || requestParameters.oauthSrToken === undefined) {
        throw new runtime.RequiredError('oauthSrToken', 'Required parameter requestParameters.oauthSrToken was null or undefined when calling startSelfReset.');
      }
      if (requestParameters.selfResetStatusRequestDto === null || requestParameters.selfResetStatusRequestDto === undefined) {
        throw new runtime.RequiredError('selfResetStatusRequestDto', 'Required parameter requestParameters.selfResetStatusRequestDto was null or undefined when calling startSelfReset.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      if (requestParameters.oauthSrToken !== undefined && requestParameters.oauthSrToken !== null) {
        headerParameters['oauth-sr-token'] = String(requestParameters.oauthSrToken);
      }
      const response = yield this.request({
        path: "/public/selfreset/start",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.SelfResetStatusRequestDtoToJSON(requestParameters.selfResetStatusRequestDto)
      });
      return new runtime.VoidApiResponse(response);
    });
  }
  /**
   * Registra l\'inizializzazione di un processo di self reset
   */
  startSelfReset(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.startSelfResetRaw(requestParameters);
    });
  }
  /**
   * Aggiorna lo stato di un processo di self reset
   */
  updateStatusSelfResetRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.oauthSrToken === null || requestParameters.oauthSrToken === undefined) {
        throw new runtime.RequiredError('oauthSrToken', 'Required parameter requestParameters.oauthSrToken was null or undefined when calling updateStatusSelfReset.');
      }
      if (requestParameters.selfResetUpdateStatoRequestDto === null || requestParameters.selfResetUpdateStatoRequestDto === undefined) {
        throw new runtime.RequiredError('selfResetUpdateStatoRequestDto', 'Required parameter requestParameters.selfResetUpdateStatoRequestDto was null or undefined when calling updateStatusSelfReset.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      if (requestParameters.oauthSrToken !== undefined && requestParameters.oauthSrToken !== null) {
        headerParameters['oauth-sr-token'] = String(requestParameters.oauthSrToken);
      }
      const response = yield this.request({
        path: "/public/selfreset",
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.SelfResetUpdateStatoRequestDtoToJSON(requestParameters.selfResetUpdateStatoRequestDto)
      });
      return new runtime.VoidApiResponse(response);
    });
  }
  /**
   * Aggiorna lo stato di un processo di self reset
   */
  updateStatusSelfReset(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.updateStatusSelfResetRaw(requestParameters);
    });
  }
}
exports.SelfResetControllerApi = SelfResetControllerApi;
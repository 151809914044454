import * as React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/Overlay";

interface Props {
  title: string
  width?: number
  height?: number
  keepSize?: boolean
  green?: boolean
  white?: boolean
  red?: boolean
  active?: boolean
  disabled?: boolean
  monochrome?: boolean
  tooltip?: string
  onClick?: any
  className?: string
  rotation?: number
  style?: React.CSSProperties
  placement?: Placement
}

export const ICON_ALIAS: {
  [key: string]: string;
} = {
  "menu.bonifico_sepa": "pagare_bonifici_sepa",
  "menu.bonifico_detrazioni_fiscali": "pagare_bonifici_bonifico_detrazioni",
  "menu.bonifico_ripetitivo": "pagare_bonifici_bonifico_ripetitivo",
  "menu.bollo_aci": "pagare_tasse_cbill_aci",
  "menu.cbill": "pagare_tasse_cbill_pagopa",
  "menu.f24": "pagare_tasse_f24",
  "menu.f24_elide": "pagare_tasse_f24_elide",
  "menu.f24_semplificato": "pagare_tasse_f24_semplificato",
  "menu.f24_ordinario": "pagare_tasse_f24_ordinario",
  "menu.f24_accise": "pagare_tasse_f24_accise",
  "menu.rav": "pagare_tasse_rav",
  "menu.pagare_e_trasferire": "pagare_pagare",
  "menu.pagamenti_effettuati": "pagare_pagare",
  "menu.conti_correnti": "pagare_conti_correnti",
  "menu.bilancio": "pagare_conti_correnti_conti_correnti_bilancio",
  "menu.saldo_e_movimenti": "pagare_conti_correnti_lista_movimenti",
  "menu.addebito_interessi": "pagare_altri-pagamenti_interessi",
  "menu.assegni": "pagare_altri-pagamenti_assegni",
  "menu.addebiti_diretti_sdd": "conti_e_carte_sdd",
  "menu.carte": "pagare_carte_carte",
  "menu.carta_conto": "pagare_carte_carta_e_conto",
  "menu.carta_prepagata": "pagare_carte_carta_prepagata",
  "menu.carta_di_credito": "pagare_carte_carta_di_credito",
  "menu.bonifici_e_giroconti": "pagare_bonifici_bonifici",
  "menu.ricariche": "pagare_ricariche_ricariche",
  "menu.ricarica_telefonica": "pagare_ricariche_cell",
  "menu.ricarica_carta_conto": "pagare_ricariche_carta_e_conto",
  "menu.altoadige_pass": "pagare_ricariche_AAP_ricarica",
  "menu.ricarica_carta_prepagata": "pagare_ricariche_carta_prepagata",
  "menu.tasse_e_tributi": "pagare_tasse_tasse",
  "menu.altri_pagamenti": "pagare_altri_pagamenti",
  "menu.bollettino_bancario_freccia": "pagare_bolletini_bollettino_freccia",
  "menu.bollettino_postale": "pagare_bolletini_bollettini",
  "menu.bollettino_postale_bianco": "pagare_bolletini_bollettino_bianco",
  "menu.bollettino_bianco": "pagare_bolletini_bollettino_bianco",
  "menu.bollettino_postale_premarcato": "pagare_bolletini_bollettino_premarcato",
  "menu.bollettino_premarcato": "pagare_bolletini_bollettino_premarcato",
  "menu.mav": "pagare_bolletini_mav",
  "menu.riba": "pagare_altri_pagamenti_RIBA",
  "menu.prelievo_cardless": "pagare_prelievo_cardless_prelievo_cardless",
  "menu.scansione_qr": "sca_qr",
  "menu.prelievo_sos": "pagare_prelievo_sos",
  "menu.bancomat_pay": "pagare_bancomat_pay_bancomat_pay",
  "menu.oneclick": "profilo_gestione_rapporti_1click",
  "menu.rubrica_beneficiari": "pagare_rubrica",
  "menu.archivio_disposizioni": "pagare_altri_pagamenti",
  "menu.risparmiare": "risparmiare_risparmiare",
  "menu.depositi_risparmio": "risparmiare_deposito_risparmio_deposito_risparmio",
  "menu.libretti_risparmio": "risparmiare_libretto_libretto",
  "menu.conto_deposito": "risparmiare_risparmiare",
  "menu.movimenti_conto_deposito": "risparmiare_conti_deposito_conti_deposito_movimenti",
  "menu.bonifico_conto_deposito": "risparmiare_conti_deposito_conti_deposito",
  "menu.time_deposit": "risparmiare_time_deposit_time_deposit",
  "menu.elenco_time_deposit": "risparmiare_time_deposit_time_deposit",
  "menu.nuovo_time_deposit": "risparmiare_time_deposit_time_deposit_sottoscrizione",
  "menu.finanziare": "finanziare_finanziare",
  "menu.prestiti": "finanziare_piano_finanziamento_piano_finanziamento",
  "menu.prestito_idea": "finanziare_cofidis",
  "menu.prestito_green": "finanziare_piano_finanziamento_piano_finanziamento",
  "menu.mutui": "finanziare_piano_mutuo", //"finanziare_piano_mutuo_tuo_piano",
  "menu.prestito_cofidis": "finanziare_cofidis",
  "menu.elenco_rapporti": "profilo_gestione_rapporti_elenco_rapporti",
  "menu.gestione_credenziali": "profilo_gestione_credenziali",
  "menu.consensi_terze_parti": "consensi-terze-parti",
  "menu.fido_isi_dispo": "finanziare_fido",
  "menu.simula_mutuo": "finanziare_simula_mutuo",
  "menu.investire": "investire_investire",
  "menu.gestione_investimenti": "investire_gestire_gestire",
  "menu.compravendita": "investire_gestire_gestire_compra_vendi",
  "menu.cerca_titoli": "investire_gestire_gestire_compra",
  "menu.titoli_preferiti": "investire_gestire_gestire_vendi",
  "menu.mercato": "investire_gestire_gestire_mercato",
  "menu.quotazioni": "investire_gestire_gestire_quotazioni",
  "menu.watchlist": "investire_gestire_gestire_watchlist",
  "menu.situazione_titoli": "investire_situazione_situazione",
  "menu.flussi_attesi": "investire_situazione_situazione_analisi",
  "menu.portafoglio_titoli": "investire_situazione_situazione_portafoglio_titoli",
  "menu.analisi": "investire_situazione_situazione_analisi",
  "menu.zainetto_fiscale": "investire_situazione_situazione_zainetto_fiscale",
  "menu.assicurare": "assicurare_assicurare",
  "menu.guidare": "guidare_guidare",
  "menu.illuminare": "guidare_illuminare_e_riscaldare",
  "menu.bisogni_assicurativi": "assicurare_assicurare_zurich",
  "menu.polizze": "assicurare_assicurare_polizze",
  "menu.conti_e_carte": "conti_e_carte_conti_e_carte",
  "copia": "copia_mini",
  "modifica": "azioni_modifica",
  "modifica_mini": "azioni_mini_modifica_mini",
  "form-conferma": "form_conferma",
  "esporta_pdf": "azioni_esporta_pdf",
  "imposta-preferito": "azioni-imposta-preferito_mini",
  "menu.comunicazioni": "comunicazioni-comunicazioni",
  "pagamenti_pos": "pagamenti_pos",
  "commissioni_bancarie": "commissioni_bancarie",
  "finanziamento_ammortamento": "finanziamento_ammortamento",
  "situazione_scadenza_titoli": "situazione_scadenza_titoli",
  "menu.time_deposit_box": "azioni_time_deposit_box",
  "menu.riepilogo_patrimonio": "profilo_patrimonio",
  "menu.patrimonio": "profilo_patrimonio",
  "attivacarta": "attiva_carta_debito",
  "carte": "carte_debito_credito_smartcard",
  "conticorrenti": "conti_correnti",
  "depositi": "conti_deposito_time_deposit",
  "gas_luce": "gas_luce",
  "isi": "internet_banking",
  "investimenti": "investimenti",
  "libretti": "libretti_risparmio",
  "massimali": "modifica_massimali",
  "mutuo": "mutuo",
  "noleggioauto": "noleggio_auto",
  "prestito": "prestiti",
  "prestiti": "prestiti",
  "assicurazioni": "prodotti_assicurativi",
  "ricontattami": "ricontattato",
  "varie": "varie",
  "menu.storico_ordini": "gestire_storico_ordine",
  "upload": "azioni-carica",
  "menu.tributi_locali": "tributi_locali",
  "menu.tributi_locali_elenco": "tributi_locali",
  "menu.tributi_locali_terzi": "tributi_locali",
  "menu.rendimenti": "rendimenti_titoli",
  "menu.pronti_contro_termine": "PCT",
  "navigazione-search": "navigazione-search",
  "dettaglio-mini": "dettaglio-mini",
  "menu.polizze_investimento": "polizze_investimento",
  "menu.i_tuoi_dati": "i-tuoi-dati",
  "menu.archivio_bozze": "pagare_pagare",
  "menu.archivio_ripetitivi": "pagare_pagare",
  "menu.per_te": "per-te",
  "servizi": "servizi",
  "mirino-green": "mirino-green",
  "asset-class": "asset-class",
  "asset-type": "asset-type",
  "rimuovi": "rimuovi_mini",
  "home-profilo-dati-personali": "profilo",
  "home-profilo-sicurezza": "profilo_accesso",
  "vai-documenti": "comunicazioni-comunicazioni",
  "mutui-e-prestiti": "finanziare_finanziare",
  "home-profilo-impostazioni": "area-personale-impostazioni-canali",
  "shield": "shield",
  "ricarica-cellulare-trusted": "ricarica_cellulare_trusted",
  "ricarica-cellulare": "ricarica_cellulare",
  "paga-f24-trusted": "paga_f24_trusted",
  "paga-f24": "paga_f24",
  "paga-bollo-trusted": "paga_bollo_trusted",
  "paga-bollo": "paga_bollo",
  "paga-alto-adige-pass-trusted": "paga_alto_adige_pass_trusted",
  "paga-alto-adige-pass": "paga_alto_adige_pass",
  "fai-bonifico": "fai_bonifico",
  "fai-bonifico-trusted": "fai_bonifico_trusted",
  "nuovo-beneficiario": "nuovo-beneficiario",
  "no-beneficiari": "no-beneficiari",
  "menu.la_tua_banca": "la-tua-banca",
  "menu.contratti": "contratto-generale",
  "menu.documenti": "documento-generico",
  "menu.contatti": "contatti",
  "menu.contattaci": "contatti",
  "menu.variazione_canali": "canali",
  "menu.variazione_canali_comunicazione": "canali",
  "menu.faq": "faq",
  "menu.neosurance_digital_insurance": "assicurare_digital_insurance",
  "menu.neosurance_polizze_digital": "assicurare_gestione_polizze",
  "menu.neosurance_acquisto_polizza": "assicurare_protection_mountain",
  "tour": "tour-play",
  "menu.dati_personali": "profilo_dati_personali",
  "menu.accesso_e_sicurezza": "accesso_sicurezza",
  "menu.gestione_utente": "impostazioni",
  "menu.firma_proposte": "firma_proposte",
  "menu.blocco_carte": "blocco_carte",
  "menu.blocco_utente": "blocco_utente",
  "menu.limiti_operativi": "limiti_operativi",
  "aperturaconto": "conti_correnti",
  "infoconto": "conti_correnti",
  "menu.questionario_mifid": "questionario_mifid",
  'menu.consulente_clean': 'consulente_clean',
  "menu.variazione_canali_fondi": "canali",
  "menu.appuntamenti": "menu.appuntamenti",
  "menu.questionario_adeguata_verifica": 'questionario_adeguata_verifica',
  "menu.ultimi_accessi": 'ultimo_accesso',
  "menu.carta_civipay": 'pagare_carte_carta_e_conto',
  "menu.bonifico_estero": 'menu.bonifico_estero',
  "menu.richieste": "menu.richieste",
  "menu.nexi_debit": "menu.nexidebit",
  "menu.ricarica_civipay": "menu.ricarica_civipay"
};

/*
usage:
  <div style={{backgroundColor: "#ccc"}}>
    <BankIcon 
        title={"pagare_bonifici_sepa"} 
        tooltip={"Bonifici SEPA"}
        // white
        // disabled 
        // monochrome
        onClick={() => {console.log("CLICKKKKK")}}               
      />                   
  </div>      
*/

const BankIcon: React.FC<Props> = (props: Props) => {
  const {
    title,
    width,
    height,
    keepSize,
    white,
    red,
    active,
    disabled,
    monochrome,
    tooltip,
    onClick,
    className,
    rotation,
    placement,
    style,
    green
  } = props;

  const defaultSize = keepSize ? undefined : 44;

  // icone non ancora convertite o non gestite
  const legacyGetIcon = (title: string) => {
    let Icon = undefined;
    let IconWhite = undefined;
    switch (title) {
      case "form.data":
        Icon = require("assets/icons/form/data.svg");
        break;
      case "info.tooltip":
        Icon = require("assets/icons/form/info_tooltip.svg");
        break;
      case "help":
        Icon = require("assets/icons/help/help.svg");
        break;
      case "rubrica":
        Icon = require("assets/icons/pagare/rubrica.svg");
        break;
      case "elenco_pagamenti":
        Icon = require("assets/icons/pagare/altri_pagamenti.svg");
        break;
      case "logout":
        Icon = require("assets/icons/logout.svg");
        break;
      case "cta.revoca":
        Icon = require("assets/icons/azioni/mini/revoca_mini.svg");
        break;
      case "cta.ripeti":
        Icon = require("assets/icons/azioni/mini/ripeti_mini.svg");
        break;
      case "cta.esegui":
        Icon = require("assets/icons/azioni/mini/esegui-mini.svg");
        break;
      case "cta.modifica":
        Icon = require("assets/icons/azioni/mini/modifica_mini.svg");
        break;
      case "cta.elimina":
        Icon = require("assets/icons/azioni/mini/chiudi.svg");
        break;
      case "cta.vai_elenco":
        Icon = require("assets/icons/vai_elenco.svg");
        break;
      case "profilo":
        Icon = require("assets/icons/profilo/profilo.svg");
        break;
      case "mirino":
        Icon = require("assets/icons/random/mirino.svg");
        break;
      case "spinner":
        Icon = require("assets/image/loader.gif");
        break;
      case "azioni.aggiungi_a_calendario_red":
        Icon = require("assets/icons/azioni/aggiungi_a_calendario_red.svg");
        break;
      case "icon-funerale":
        Icon = require("assets/icons/icon-funerale-spk.svg")
        break;
      case "icon-ospedale":
        Icon = require("assets/icons/icon-ospedale-spk.svg")
        break;
      case "icon-trasporto":
        Icon = require("assets/icons/icon-trasporto-spk.svg")
        break;
      case "menu.neosurance_acquisto_polizzaPET":
        Icon = require("assets/icons/protection_pet.svg")
        break;
      case "menu.consulente_clean":
        Icon = require("assets/icons/consulente_clean.svg")
        break;
      case "menu.appuntamenti":
        Icon = require("assets/icons/data.svg")
        break;
      case "freccia_destra":
        Icon = require("assets/icons/freccia_destra.svg")
        break;
      case "back":
        Icon = require("assets/icons/navigazione/indietro_red.svg")
        break;
      case "edit":
        Icon = require("assets/icons/edit.svg")
        break;
      case "upload":
        Icon = require("assets/icons/carica.svg")
        // case "home-profilo-dati-personali":
        //   Icon = require("assets/icons/profilo/dati_personali/dati_personali.svg");
        //   break;
        break;
      case "scarica":
        Icon = require("assets/icons/scarica.svg")
        break;
      case "next":
        Icon = require('assets/icons/freccia-destra-rossa.svg')
        break;
      case "rinnova":
        Icon = require('assets/icons/rinnova.svg')
        break;
      case "menu.questionario_adeguata_verifica":
        Icon = require('assets/icons/adv.svg')
        break;
      case "menu.ultimi_accessi":
        Icon = require('assets/icons/ultimi_accessi.svg')
        break;
      case "menu.zainetto_fiscale":
        Icon = require('assets/icons/investire/situazione/situazione_zainetto_fiscale.svg')
        break;
      case "menu.ricarica_civipay":
        Icon = require('assets/icons/pagare/ricariche/carta_civipay.svg')
        break;
      case "menu.neosurance_acquisto_polizzaTravel":
        Icon = require('assets/icons/icon-travel.svg')
        break;
      case "menu.bonifico_estero":
        Icon = require('assets/icons/pagare/bonifici/bonifico_estero.svg')
        break;
      case "menu.carta_nexi_debit":
        Icon = require('assets/icons/nexi_debit.svg')
        break;
      case "menu.acquisto_nexi_debit":
        Icon = require('assets/icons/nexi_debit.svg')
        break;
      case "menu.richieste":
        Icon = require('assets/icons/comunicazioni/comunicazioni_cliente_banca.svg')
      default:
        break;
    }
    return white ? IconWhite : Icon;
  };

  const renderTooltip = (el: string) => (
    <Tooltip id="button-tooltip">{el}</Tooltip>
  );

  const renderImage = (Icon: string) => {
    const singleSvg = legacyGetIcon(title);
    if (singleSvg) {
      // single
      return (
        <img
          className={`${onClick ? "general-button" : ""} ${className || ""}`}
          src={singleSvg}
          style={{
            height: height || defaultSize,
            width: width || defaultSize,
            ...style,
          }}
          onClick={(e: any) => {
            if (!!onClick) {
              e.stopPropagation();
              onClick();
            }
          }}
        />
      );
    } else {
      // inline in sprite.svg
      return (
        <svg
          className={
            `${(onClick || active) && !disabled
              ? 'general-button icone-azioni'
              : ''
            }${red ? ' icona-rossa' : ''
            }${green ? ' icona-green' : ''
            }${white ? ' icona-in-negativo' : ''
            }${monochrome ? ' icona-monocromatica' : ''
            }${disabled ? ' icona-disabilitata' : ''
            }${!!className ? ' ' + className : ''
            }`
          }
          style={{
            height: height || defaultSize,
            width: width || defaultSize,
            minHeight: width || defaultSize,
            minWidth: width || defaultSize,
            transform: rotation ? `rotate(${rotation || 0}deg)` : "none",
            ...style,
          }}
          onClick={(event: any) => {
            if (!!onClick) {
              event.stopPropagation();
              !disabled && onClick(event);
            }
          }}
        >
          <use
            xlinkHref={`/icon-sprite.svg#icon-${(
              ICON_ALIAS[Icon] || Icon
            ).replace(/\./g, "_")}`}
          />
        </svg>
      );
    }
  };

  return (
    <>
      {tooltip
        ? (
          <OverlayTrigger
            placement={placement || "bottom"}
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(tooltip)}
          >
            {renderImage(title)}
          </OverlayTrigger>
        ) : (
          <>{renderImage(title)}</>
        )
      }
    </>
  );
};

export default BankIcon;
